
@import '../../scss/colors.scss';

// Order Hirtory Details
.order-details-bg{
  background-image: url(../TuneUp/other_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  min-height: 91vh;
  background-attachment: fixed;
}
@media(max-width: 768px){
  .order-details-bg{
    background-image: url(../TuneUp/OrderHisBg.svg);
    background-repeat: no-repeat;
    min-height: 100vh !important;
    background-size: 100%;
    background-position: revert !important;
    background-attachment: revert !important;
  }
}

.odr-hsdd-wrp{
    .odr-hsdd-status{
      background-color: #FFB400;
      // margin-bottom: 45px;
      .odr-hsdd-dt-wrp{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;
        padding-top: 15px;
        .odr-hsdd-cal{
          color: $white;
          display: flex;
          padding: 0 5px;
          align-items: center;
          strong{
            font-weight: 800;
            font-size: 12px;
            line-height: 15px;
            display: inline-block;
            padding-left: 4px;
          }
  
        }
      }
      .odr-hsdd-cnt{
        padding-left: 1rem;
        padding-right: 0.8rem;
        padding-top: 0.4rem;
        .order-id{
          color: $white;
          display: flex;
          align-items: center;
          // padding-bottom: 15px;
          &-lbl{
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
          }
          &-name{
            font-weight: 800;
            font-size: 16px;
            line-height: 17px;
            color: #FFFFFF;
            padding-left: 3px;
            letter-spacing: 1px;
          }
        }
        .ser-provided-dt{
          .ser-pro-lbl{
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
          }
          .ser-pro-name{
            font-weight: 800;
            font-size: 16px;
            line-height: 17px;
            color: #FFFFFF;
            padding-left: 8px;
            letter-spacing: 1px;
          }
        }
      }
      .odr-price{
        width: 70px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        border-radius: 70px;
        position: absolute;
        bottom: -35px;
        left: calc(50% - 35px);
        &-lbl{
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: $primary;
        }
      }
      
    }
    .status-confirm{
      background-color: #24AD65;
    }
  
    .odr-mec-details{
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 0.6rem;
      .odr-mec-dd-in{
        background-color: $white;
        display: flex;
        align-items: center;
        flex-direction: row;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
        border-radius: 0.3rem;
        padding: 0.4rem 0.9rem;
        .odr-mec-ic{
          width: 60px;
          height: 60px;
          border-radius: 60px;
          border: 1px solid $black;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            max-width: 100%;
            width: 40px;
            height: auto;
          }
        }
        .odr-mec-cnt{
          padding-left: 1.1rem;
          .odr-mec-pend-sts{
            font-weight: 300;
            font-size: 1rem;
            line-height: 17px;
            color: #000;
          }
          .odr-mec-lbl{
            display: block;
            font-weight: 300;
            font-size: 13px;
            line-height: 15px;
            color: $dark;
          }
          .odr-mec-name{
            padding-top: 0px;
            display: block;
            font-weight: 300;
            font-size: 1rem;
            line-height: 20px;
            color: #000000;
          }
        }
      }
    }
    .odr-payment-wrp{
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 0.6rem;
      background-color: $white;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 0.3rem;
      padding: 15px 20px;
      .odr-payment-dd{
        h4{
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          margin-bottom: 5px;
        }
        span {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
        }
        address{
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          padding-bottom: 1.2rem;
        }
      }
      .odr-payment-address{
        // padding-top: 20px;
  
      }
    }

    .order-payment-details{
      margin-top: -17px !important;
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      padding-top: 0.3rem !important;
    }

    .odr-his-btns{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 0.4rem;
      margin-left: 1rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      div{
        display: flex;
        flex: 1;
        // padding: 0 5px;
        // justify-content: space-between;
      }
    }

  }

  .header-text{
    text-align: center;
    display: flex !important;
    justify-content: center;
    margin: 0 auto;
    padding-right: 3rem;
    p {
      font-size: 1.5rem !important;
    }
  }

  .order-heading{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 4px;
    // margin-left: 1.3rem;

  }

  .order-style{
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 18px !important;
  }

  .order-wrapper{
    border: 1px dotted white;
    padding: 4px 12px;
    border-radius: 3px;
    // margin-top: 10px;
  }

  .order-status{
    // margin-top: -10px;
    margin-right: 5px;
    margin-left: auto;
  }

  .img-calendar{
    width: 20px;  
    height: 20px;
  }

  .orders-data{
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;
    margin-block-end: -45px;
    padding-bottom: 0.6rem;
    // margin-left: 25px;
    margin-top: 1.5rem;
    span{
      margin-left: 5px;
    }
    span:last-child{
      margin-right: 5px;
    }
  }

  .order-status-open{
    display: grid;
  }

  .order-mech{
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 16px !important;
  }

  .order-calendar{
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .order-date{
    font-weight: 400px !important;
    font-size: 1rem;
    line-height: 16px;
   
  }

  .order-time{
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .img-time{
    height: 20px;
    width: 20px;
  }

  .orderdata-time{
   
    font-size: 1rem;
    line-height: 16px;

  }

  .order-duration{
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
    // margin-right: 25px;
  }

  .img-duration{
    height:20px;
    width:20px;
    
  }

  .orderdata-duration{   
    font-size: 1rem;
    line-height: 16px;
  }

  .order-service-location{
    font-weight: 700 !important;
    font-size: 1rem !important;
    line-height: 18px !important;
    color: #000 !important;
  }
  
  .order-banner-image {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .order-service-cost{
    position: absolute;
    top: 40px;
    span{
      color: #FF5F07;
      font-size: 1.7rem;
      font-weight: bold;
    }
  }

  
  .order-service-name{
    text-align: center;
    width: 11rem;
    margin: 0 auto;
    background-color: #507C76;
    border-radius: 0.3rem;
    margin-top: -68px;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translate(-50%);
    p {
      color: #FFFFFF;
    }
  }

  .order-address{
    font-weight: 300 !important;
    line-height: 18px !important;
    font-size: 1rem !important;
    color: #000000;
  }

  // Modal design
  .modal-Overlay{
    // background: rgba(0, 0, 0, 0.7);
    position: fixed;
    inset: 0;
    
  }
  .modal-hd{
    h5{
      
    font-size: 1.15rem;
    padding: 15px 20px 15px;
    color: #000;
    font-weight: 300;

    }
  }

  .order-cancel-popup{
    .cancel-title{
      font-size: 1.3rem !important;
      // font-weight: 300 !important;
      text-align: center !important;
    }
  }
  .modal-prmt-btns{
    display: flex;
    flex-direction: row;
    justify-content: right;
    flex-wrap: wrap;
    // margin: 15px;
      div {
        display: flex;
        flex: 0 0 90px;
        padding: 0 5px;
        justify-content: space-between;
      }
  }

  // .delete-modal-btn{
  //   margin: 0px !important;
  //   margin-top: 15px !important;
  // }


  .order-payment-details{
    margin-top: -5.58rem !important;
    // height: 145px;
    p{
      margin-top:-10px;
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
    .order-service-charge{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 22px;
      p{
        font-weight: 300;
        font-size: 1rem;
      }
      span{
        font-weight: 300;
        font-size: 1rem;
        color: #000000;
      }
    }
    .order-parts{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 22px;
      p{
        font-weight: 300;
        font-size: 1rem;
      }
      span{
        font-weight: 300;
        font-size: 1rem;
        color: #000000;
      }
      
    }
    .order-tips{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 22px;
      p{
        font-weight: 300;
        font-size: 1rem;
      }
      span{
        font-weight: 300;
        font-size: 1rem;
        color: #000000;
      }
    }

    .order-applicable-tax{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 22px;
      p{
        font-weight: 300;
        font-size: 1rem;
      }
      span{
        font-weight: 300;
        font-size: 1rem;
        color: #000000;
      }
    }

  }

  .order-total{
    display:flex;
    flex-direction: row !important;
    justify-content: space-between;
    margin-bottom: 0rem !important;
    margin-top: -20px !important;
    height: 24px;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    background-color: #507C76 !important;
    border-radius: 6px !important;
    align-items: center;
    p{
        font-weight: 700;
        font-size: 1rem;
        color: #FFFFFF;

      }
      span{
        font-weight: 700;
        font-size: 1rem;
        color: #FFFFFF;

      }
  }

  .order-payment-method{
    line-height: 18px;
    // height: 80px;
    margin-top: 0.5rem !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 5px !important;

   h6{
    font-weight: 700;
    font-size: 1rem;
    color:#000000;
    margin-top: -3px;
    margin-bottom: 5px;
   } 
   p{
      font-weight: 300;
      font-size: 1rem;
      color: #000000 !important;
   }
    span{
      font-weight: 300;
      font-size: 1rem;
      color: #000000 !important;
   }
  }

  .order-help{
    font-weight: 300;
    font-size: 1rem;
    margin-top: 0.6rem;
    span{
      font-weight: 700;
    }
  }

  .order-help-display-none{
    display: none;
  }
  .order-complete-status-footer{
    margin-bottom: 1rem;
  .order-help-custom{
    margin-left: 1rem;
    margin-top: 0.4rem;
    font-weight: 300;
    font-size: 1rem;
    color: #000000;
    span{
      font-weight: 700;
    }
  }
}

.horizontal-scroll-view {
  width: 100%;
}

.scroll-container {
  // display: flex;
  overflow: hidden;
  height: 5.7rem;
  width: 100%;
  border-radius: 0.3rem;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.1);
  margin-top: -10px;
}

.length-txt{
  position: absolute;
  width: 93%;
  padding-top: 5.7rem;
  transform: translateY(-50%);
  text-align: center; 
  cursor: pointer;
}
.length-txt1{
  font-size: 3rem;
  color: #FFF;
  font-weight: 700;
}

/* Rest of your CSS styles */

