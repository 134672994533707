@import './colors.scss';

*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: $secondary;
    // background-color: $primary-light-bg;
    // background-image: url('/src/Views/ProfileMenu/images/profile-menu-background.png');

}

// Link Color
a, .link{
    color: $primary;
    text-decoration: none;
}
a:hover, 
a:focus,
.link:hover,
.link:focus {
    color: $primary-hover;
}

// Image
// img{
//     max-width: 100%;
//     height: auto;
// }

// Head Tag
.h1{
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    color: $dark;
}
.h2{
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 32px;
    color: $dark;
}
.h3{
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: $dark;
}
.h4{
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: $dark;
}
.h5{
    font-size: 0.9rem;    
    line-height: 17px;
    color: #4A4949;
    text-align: center;
}

p{
    font-weight: 300;
    font-size: 1rem;
    line-height: 18px;
    color: #000000;
}

.terms-condition-group{
    text-align: right !important;
}
.terms{
    font-size: 0.8rem;
    text-align: center;
}
// Text align
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}

// Display Flex
.d-flex{
    display: flex;
}
.align-items-center{
    align-items: center;
}
.dn{
    display: none !important;
}
.p-rel{
    position: relative;
}
.height-full-page{
    height: 100vh;
}

.pb10 { padding-bottom: 10px;
}
.pb15 { padding-bottom: 15px;
}
.pb20 { padding-bottom: 20px;
}
.pt10 { padding-top: 10px;
}
.pt15 { padding-top: 15px;
}
.pt20 { padding-top: 20px;
}

.mb10 { margin-bottom: 10px;
}
.mb15 { margin-bottom: 15px;
}
.mb20 { margin-bottom: 20px;
}
.mt10 { margin-top: 10px;
}
.mt15 { margin-top: 15px;
}
.mt20 { margin-top: 20px;
}
// error message
.error {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: #ffffff;
    background: #d50202;
    padding: 10px 15px;
}
.sr-err-msg {
    background-color: #ffe0e0;
    padding: 10px 15px;
    border-radius: 4px;
    padding-left: 30px;
    margin-bottom: 15px;
    .sr-err-data{
        font-size: 13px;
        line-height: 1.6;
        padding-bottom: 5px;
        color: #d50202;
        &:last-child {
            padding-bottom: 0;
        }
    }
}
.success {
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 30px;
    display: block;
    color: #ffffff;
    background: #007056;
    padding: 10px 15px; 
}
// button
.btn{
    background: $primary;
    border: 1px solid $primary;
    border-radius: 30px 2px;
    height: 48px;
    width: 100%;
    font-size: 14px;
    line-height: 48px;
    display: block;
    text-align: center;
    color: $white;
    // text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.btn-w-100 {
    width: 100px;
}
.btn-w-150 {
    width: 150px;
}
.btn-w-200 {
    width: 200px;
}
.btn:hover, 
.btn:focus {
    background: $primary-hover;
    border: 1px solid $primary-hover;
    color: $white;
}
.btn-primary{
    background: $primary;
    border: 1px solid $primary;
}


// form
.form-hd-lbl{
    padding-top: 10px;
    padding-bottom: 20px;
    h3{
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
    }
}

.bike-info-hd{
    padding-top: 0px !important;
    padding-bottom: 0.8rem !important;
}
.service-hd{
    padding-top: 1.2rem;
    padding-bottom: 1.3rem;
}

.bikeinfo-hd{
    padding-top: 0.9rem !important;
    padding-bottom: 0.8rem !important;
}
.zipcode-grp{
    margin-bottom: 1rem !important;
}
.date-hd{
    padding-top: 0px !important;
    padding-bottom: 1.4rem !important;
}
.cardinfo-hd{
    padding-top: 0.9rem !important;
    padding-bottom: 0.4rem !important;
}
.timeslot-btn{
    margin-top: 1rem;
    margin-bottom: 0rem !important;
}
.form-group-wrp{
    display: flex;
    flex-direction: column;
}
.form-group{
    position: relative;
    margin-bottom: 1.25rem;
}
.type-group{
    margin-bottom: 1rem !important;
}
.forgot-group{
    margin-bottom: 1.25rem !important;
}
.sign-in-group{
    display: flex !important;
    justify-content: space-between !important;
}
.signin-form-group{
    border: 0.5px solid #888888 !important;
}
.signin-form-group:hover{
    border: 0.5px solid #ff5f07 !important;
}
.form-textbox{
    margin-bottom: 1rem !important;
}

.bike-select{
    margin-bottom: 1.5rem !important;
}

.service-grp{
    margin-bottom: 1rem !important;
}

.signin-btn{
    margin-top: 2rem !important;
    margin-bottom: 1rem !important;
}
.tune-form-group{
    margin-bottom: 0.7rem;
  }
.form-reg-btn{
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1.2rem;
}

.form-lbl {
    position: absolute;
    top: 16px;
    // bottom: 16px;
    left: 10px;
    transition: all 200ms;
    color: $dark-light;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    padding: 0 5px;
    // position: relative;
    &::after{
        content: "";
        position: absolute;
        background-color: red;
    }
}
.form-lbl-payment{
    // bottom: 87px;
    font-size: 10px;
}
.tip-lbl{
    top: 92px !important;
}
.form-control {
    border: 0.5px solid #4A4949;
    width: 100%;
    height: 46px;
    padding: 10px 8px;
    border-radius: 0.4rem;
    background-color: $white;
    font-size: 1rem;
    transition: 0.3s;
    text-indent: 0;
    font-family: 'Dubai';
    font-style: normal;
    color: #000000;
}
.form-control-textarea{
    height: 60px;
}
.form-textarea{
    height: 120px;
    border: none !important;
    border-radius: 0.63rem !important;
}
.form-control:focus,
.form-control:active,
.form-control:focus-visible{
    border: 1px solid $primary;
    outline: 0;
}
.form-control:disabled {
    // background-color: $primary-light-bg;
}
.form-control:focus + .form-lbl, .form-control:disabled + .form-lbl, .form-control:read-only + .form-lbl, .form-control:valid + .form-lbl {
    transform: translate3d(0, -160%, 0);
    font-size: 0.875rem;
    background: $white;
    color: $black;
}
.form-control-textarea:focus + .form-lbl, .form-control-textarea:disabled + .form-lbl, .form-control-textarea:read-only + .form-lbl, .form-control-textarea:valid + .form-lbl {
    transform: translate3d(0, -154%, 0);
    font-size: 1rem;
    background: $white;
    color: $black;
}

.form-group-right-ic{
    .form-control{
        padding-right: 29px;
    }
    .date-ic{
        position: absolute;
        right: 13.5px;
        padding-top: 10px
    }
}

.react-dropdown .dropdown__control{
    // border: 1px solid $dark-light;
    width: 100%;
    height: 48px;
    padding: 0 8px;
    border-radius: 0.38rem;
    border: 0.5px solid #000;
    background-color: $white;
    font-size: 14px;
    transition: 0.3s;
    text-indent: 0;
}
.react-dropdown .dropdown__single-value {
    color: #000000;
    font-size: 1rem;
    font-weight: 300;
}

.react-select__menu-list {
    font-size: 1.09rem !important; /* Set your desired font size */
  }

.invalid-feedback{
    // margin-top: -15px;
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 1rem;
    color: #e00a23;
}
.error-color{
    color: #e00a23 !important;
}

// .main{
//     width: 50%;
//     margin: 0 auto;
// }
// Ride Doc Style start

// Base Style
.main-wrapper{
    max-width: 600px;
    margin: 0 auto;
    // padding: 20px;
    // background-color: #fff;
    display: flex;
    flex-direction: column;
    // min-height: calc(100vh - 70px);
    // border: 1px solid #fff1ea;
    border-radius: 10px;
    // box-shadow: 0 0 10px 0px rgba(255, 95, 7, 0.16);
    position: relative;
}

.row{
    display: flex;
    clear: both;
    flex-wrap: wrap;
    margin: 0 -7.5px;
}
.col {
    flex: 1 0 0%;
    padding: 0 7.5px;
}
.name-col{
    padding-right: 0rem !important;
}

// Header.scss
.header-wrp {
    max-width: 600px;
    margin: 0 auto;

    header {
        height: 70px;
        // background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: left;
        padding-left: 20px;
        // border-bottom: 1px solid #f1f1f1;
        // box-shadow: 0px 0px 2px rgb(0 0 0 / 15%);
        // border-radius: 0px 0px 50px 0px;

        &.hd-centered {
            justify-content: center;
            text-align: center;
            padding-left: 0;
        }

        img {
            max-width: 260px;
            // margin-top: 6px;
        }

        .header-backic {
            background-color: #FFF2EB;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            width: 36px;
            height: 36px;
            border-radius: 36px;
            display: flex;
            align-items: center;
            justify-content: center;

            .header-backic-icon {
                display: flex;
                background: transparent;
                border: none;
                cursor: pointer;
            }
        }

        .header-mnutxt {
            // padding-left: 20px;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 18px;
            text-align: center;
            color: #000000;
        }
    }
}

.ss{
    position: fixed;
    background-color: #d50202;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

// Upload file
.files-dropzone{
    // border: 1px solid #D2D2D2;
    width: 100%;
    height: 46px;
    padding: 12px 8px;
    border-radius: 0.63rem;
    background-color: #fff;
    font-size: 14px;
    // justify-content: center;
    // display: flex;
    // align-items: center;
    .inner-input{
        cursor: pointer;
        width: 8.7rem;
        height: 1.4rem;
        border: 1px solid #D9D9D9;
        background-color: #D9D9D9;
        border-radius: 0.25rem;
    }
    .file {
        padding-left: 0.5rem;
        padding-top: 0.3rem;
        padding-bottom: 0.2rem;
    }
}

// Loading CSS
.lds-ripple {
// display: inline-block;
// position: relative;
// width: 80px;
// height: 80px;

position: fixed;
left: calc(50% - 40px);
right: 0;
top: calc(50% - 40px);
bottom: 0;
text-align: center;
}
.lds-ripple div {
position: absolute;
border: 4px solid #f00;
opacity: 1;
border-radius: 50%;
animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
animation-delay: -0.5s;
}
@keyframes lds-ripple {
0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
}
4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
}
5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
}
100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
}
}

// Page not available 
.pgnotav{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    .pgnotav-hd{
        padding-top: 50px;
    }
}

// Profile Infor Email verified
.nonverify{
    background-color: $white;
    box-shadow: 0 0 10px 1px $dark-light;
    padding: 30px 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 6px;
    text-align: center;
    &-cnt{
        font-weight: 500;
        line-height: 1.5;
    }
}

// Privacy Policy


@media (max-width:599px) {
    .header-wrp header{
        height: 60px;
    }
}
.dropdown__indicator-separator  {
background: white !important;
}

