@import './../../scss/colors.scss';

// Banner css
.home_bg{
  background-image: url(../../images/home-banner-app.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  min-height: 100vh;
  background-attachment: fixed;
}
@media(max-width: 767px){
  .home_bg{
    background-image: url(../../images/homebg.svg);
    background-size: 100%;
    background-position: bottom;
    min-height: 95vh !important;
    background-attachment: revert !important;
  }
}


.signup_bg{
  background-image: url(../../images/home-banner-app.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  min-height: 100vh;
  background-attachment: fixed;
}
@media(max-width: 767px){
  .signup_bg{
    background-image: url(../../images/SigninBg.svg);
    background-size: 100%;
    background-position: bottom;
    min-height: 100vh !important;
    background-attachment: revert !important;
  }
}

.forgot_bg{
  background-image: url(../../images/home-banner-app.jpg);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  min-height: 100vh;
  background-attachment: fixed;
}
@media(max-width: 767px){
  .forgot_bg{
    background-image: url(../../images/SigninBg.svg);
    background-size: 100%;
    background-position: bottom;
    min-height: 100vh !important;
    background-attachment: revert !important;
  }
}
@media(min-width: 768px) and (max-width: 912px){
  .forgot_bg{
    min-height: 0;
    height: 93vh;
  }
}


@media(max-width: 896px){
  .ser-wrp{
    margin-left: 0%;
    margin-right: 0%;
  }
}

.booking-info{
  background-image: url('../TuneUp/other_bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  min-height: 100vh;
}
@media(max-width: 767px){
  .booking-info{
    background-image: url(../ProfileMenu/images/ProfileBg.svg);
    background-size: 100%;
    background-position: revert !important;
    min-height: 100vh !important;
    // background-attachment: revert !important;
  }
}
// @media(min-width: 768px) and (max-width: 912px){
//   .booking-info{
//     background-size: 100% 100%;
//     height: 93vh;
//   }
// }
// @media(min-width: 1280px) and (max-width: 1285px){
//   .booking-info{
//     background-size: 100% 100%;
//     height: 90vh;
//   }
// }

// @media (min-width: 769px) and (max-width: 1400px){
//   .ser-cnt{
//     margin-bottom: 87% !important;
//   }
// }
.ser-wrp {
  margin-top: 30px;
  margin-left: 10%;
  margin-right: 10%;
  // margin-bottom: 400px;
  .ser-hd {
    // padding-top: 2.3rem;
    // padding-bottom: 10px;
  }
  .ser-cnt{
    .ser-item{
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      margin: 0 10px;
      .ser-item-in{
        flex-basis: calc(35% - 40px);
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 10px;
        margin-top: 1.5rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        flex-direction: row;
        width: 40px;
        height: 78px;
        border: 0.5px solid #D2D2D2;
        border-radius: 0.375rem;
        padding: 0 20px;
        text-align: center;
        &:hover{
          .ser-link-ic{
            // background-color: $primary-hover;
            transition: 0.1s ease-in-out background-color;
          }
        }
        .ser-link-ic{
          width: 60px;
          height: 60px;
          // background-color: $primary;
          border-radius: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto;
      
          img{
            width: 54px;
            height: 54px;
            // margin-top: 76px;
          }
        }
        .ser-txt{
          color: $dark;
          font-size: 1rem;
          width: 115px;
          height: 36px;
          display: flex;
          justify-content: center;
          // align-items: center;
          // margin-top: 60px;
        }
      }
    }
  }
}
// @media(min-width: 820px) and (max-width: 912px){
//   .ser-wrp{
//     margin-top: 150px;
//   }
// }
@media(max-width: 915px){
  .ser-wrp{
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 5rem;
  }
}
@media(min-width: 414px) and (max-width: 896px){
  .ser-wrp{
    // margin-bottom: 335px;
  }
}
@media(max-width: 767px){
  .ser-link-ic{
    margin-bottom: 5px;
  }
}

// @media(min-width: 375px) and (max-width: 385px){
//   .ser-item-in{
//     flex-basis: calc(24% - 0px) !important;
//   }
// }

// @media(min-width: 386px) and (max-width: 390px){
//   .ser-item-in{
//     flex-basis: calc(24% - -1px) !important;
//   }
// }
@media (min-width: 391px) and (max-width: 414px) {
  .home-banner{
    .home-banner-in{

    }
  }
  .ser-wrp {
    .ser-hd {
      padding-top: 50px;
      // padding-bottom: 15px;
    }
    .ser-cnt{
      .ser-item{
        display: flex;
        flex-wrap: wrap;
        // justify-content: center;
        margin: 0px 20px;
        // margin: 3px -67px;
        .ser-item-in{
          // flex-basis: calc(24% - 0px);
          margin-bottom: 20px;
          margin-left: 20px;
          margin-right: 10px;
          margin-top: 20px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          flex-direction: row;
          height: 77px;
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
          // border-radius: 50px 4px;
          padding: 0 20px;
          text-align: center;
          &:hover{
            .ser-link-ic{
              // background-color: $primary-hover;
              transition: 0.1s ease-in-out background-color;
            }
          }
          .ser-link-ic{
            // width: 50px;
            // height: 50px;
            // background-color: $primary;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            img{
              width: 54px;
              height: 54px;
              // margin-top: px;
            }
          }
          .ser-txt{
            color: $dark;
            font-size: 1rem;
            line-height: 1.3;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 30px;
          }
        }
      }
    }
  }
}

// Tune Up
// .tune-up-main-wrp{
//   .tune-up-tab{
//     ul{
//       display: flex;
//       flex-direction: row;
//       list-style-type: none;
//       justify-content: space-between;
//       align-items: center;
      
//       li{
//           display: flex;
//           flex-basis: 33.33%;
//           justify-content: center;
//         a{
          
//           display: flex;
//           background: $secondary;
//           box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
//           border-radius: 35px 0px;
//           height: 56px;
//           color: #fff;
//           align-items: center;
//           width: 100%;
//           justify-content: center;
//           font-weight: 500;
//           font-size: 14px;
//           line-height: 18px;
//           &.active{
//             background: $primary;
//           }
//         }
//       }
//     }
//   }
//   .tune-amt-wrp{
//     margin-top: 30px;
//     .tune-amt-cnt{
//       background-color: #fff;
//       display: flex;
//       box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
//       border-radius: 10px;
//       padding: 25px 25px;
//       justify-content: center;
//       .tune-name-price,
//       .tune-duration{
//         font-weight: 500;
//         font-size: 16px;
//         line-height: 18px;
//         color: $black;
//         padding: 0 5px;
//         span{
//           color: $primary;
//           font-weight: 800;
//           font-size: 16px;
//         }
//       }
//     }
//     .tune-desc-wrp{
//       background: #FFFFFF;
//       box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
//       border-radius: 10px;
//       margin-top: 30px;
//       margin-bottom: 30px;
//       padding: 20px;
//       h3{
//         padding-bottom: 10px;
//       }
//       ul {
//         padding-left: 20px;
//         li{
//           padding-bottom: 5px;
//           font-weight: 400;
//           color: $dark;
//           font-size: 14px;
//           line-height: 16px;
//         }
//       }
//     }
//   }
// }


// Booking Time Screen Popup
.booking-wrp{
  // padding-left: 20px;
  // padding-right: 20px;
}
.rd-popup-hd-orderid{
  // text-align: center;
  font-weight: 500;
  // font-family: 'Dubai';
  font-size: 16px;
  padding-bottom: 7px;
  padding-left: 30px;
  padding-top: 20px;
}
.rd-popup-hd-totcost {
  font-weight: 500;
  // font-family: 'Dubai';
  font-size: 16px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-top: 7px;
}
.rd-popup-wrp{
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // position: relative;
  // background-color: rgba(255, 255, 255, 0.93);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 99;
  .rd-popup-close{
    position: absolute;
    top: 6px;
    right: 6px;
    .reg-close {
      // background: #FFF2EB;
      // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border: none;
      border-radius: 36px;
      cursor: pointer;
    }

  }
  .payment-message{
    // padding: 97px !important;
    text-align: center;
  }
  .rd-popup-content{
    padding: 20px;
    text-align: center;
    h2{
      margin-bottom: 25px;
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      color: $dark
    }
    p {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 26px;
      // max-width: 65%;
      color: #000000;
      margin: 0 auto;
    }
  }
}

.delete-popup{
  font-size: 1.3rem !important;
  max-width: none !important;
  margin: 0 !important;
}


// Order Hirtory Start
// .order-history-wrp{
//   padding: 20px;
//   .order-history-list{
//     .order-history-item{
//       .order-history-item-link{
//         display: flex;
//         background: #FFFFFF;
//         border-radius: 6px;
//         flex-direction: row;
//         height: 80px;
//         align-items: center;
//         margin-bottom: 15px;
//         padding: 0 15px;
//         box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
//         .order-hisroy-img{
//           // width: 60px;
//           // height: 60px;
//           background: #d9d9d9d9;
//           // border-radius: 15px;
//           // padding: 10px;

//           img{
//             max-width: 100%;
//             display: block;
//           }
//         }
//         .order-hisroy-item-cnt{
//           padding-left: 15px;
//           width: 100%;
//           h4 {
//             font-weight: 500;
//             font-size: 16px;
//             line-height: 18px;
//             color: $dark;
//             padding-bottom: 5px

//           }
//           .order-hisroy-item-stat{
//             display: flex;
//             width: 100%;
//             justify-content: space-between;
//             align-items: center;
//             &-txt {
//               background: #FFB400;
//               padding: 5px 0;
//               display: block;
//               border-radius: 4px;
//               font-weight: 500;
//               font-size: 11px;
//               line-height: 11px;
//               color: #FFFFFF;
//               width: 90px;
//               text-align: center;
//             }
//             .stat-completed{
//               background: #24AD65;
//             }
//             .stat-canceled{
//               background: #E94E4E;
//             }
//             .order-hisroy-item-date{
//               font-weight: 500;
//               font-size: 12px;
//               line-height: 16px;
//               color: #AAAAAA;
//             }
//           }
//         }

//       }
      
//     }
//   }
// }

// Order Hirtory Details
.odr-hsdd-wrp{
  .odr-hsdd-status{
    background-color: #FFB400;
    // margin-bottom: 50px;
    .odr-hsdd-dt-wrp{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;
      padding-top: 15px;
      .odr-hsdd-cal{
        color: $white;
        display: flex;
        padding: 0 5px;
        align-items: center;
        strong{
          font-weight: 800;
          font-size: 12px;
          line-height: 15px;
          display: inline-block;
          padding-left: 4px;
        }

      }
    }
    .odr-hsdd-cnt{
      // padding: 20px 20px 60px;
      .order-id{
        color: $white;
        display: flex;
        align-items: center;
        // padding-bottom: 15px;
        &-lbl{
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          color: #FFFFFF;
        }
        &-name{
          font-weight: 800;
          font-size: 16px;
          line-height: 17px;
          color: #FFFFFF;
          padding-left: 8px;
          letter-spacing: 1px;
        }
      }
      .ser-provided-dt{
        .ser-pro-lbl{
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          color: #FFFFFF;
        }
        .ser-pro-name{
          font-weight: 800;
          font-size: 16px;
          line-height: 17px;
          color: #FFFFFF;
          padding-left: 8px;
          letter-spacing: 1px;
        }
      }
    }
    .odr-price{
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      border-radius: 70px;
      position: absolute;
      bottom: -35px;
      left: calc(50% - 35px);
      &-lbl{
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $primary;
      }
    }
    
  }
  .status-confirm{
    background-color: #24AD65;
  }

  .odr-mec-details{
    // margin-top: 15px;
    // padding: 0 20px;
    .odr-mec-dd-in{
      background-color: $white;
      display: flex;
      align-items: center;
      flex-direction: row;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      padding: 10px 20px;
      .odr-mec-ic{
        width: 60px;
        height: 60px;
        border-radius: 60px;
        border: 1px solid $black;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          max-width: 100%;
          width: 40px;
          height: auto;
        }
      }
      .odr-mec-cnt{
        padding-left: 20px;
        .odr-mec-pend-sts{
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          color: #666666;
        }
        .odr-mec-lbl{
          display: block;
          font-weight: 300;
          font-size: 13px;
          line-height: 15px;
          color: $dark;
        }
        .odr-mec-name{
          padding-top: 5px;
          display: block;
          font-weight: 600;
          font-size: 17px;
          line-height: 20px;
          color: $primary;
        }
      }
    }
  }
  .odr-payment-wrp{
    // margin: 25px 20px 0;
    background-color: $white;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 15px 20px;
    .odr-payment-dd{
      h4{
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 5px;
      }
      span {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
      address{
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
      }
    }
    .odr-payment-address{
      // padding-top: 20px;

    }
  }
}