.contactus-wrp {
    .contactus-in{
        .contactus-box{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin: 20px -10px 10px;
            .contactus-dt{
                display: flex;
                flex-grow: 1;
                margin: 10px;
                a {
                    display: flex;
                    flex-direction: column;
                    padding: 20px;
                    border-radius: 10px;
                    background-color: #FFF2EB;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }
                &-ic{
                    width: 50px;
                    height: 50px;
                    border-radius: 40px;
                    background-color: #FF5F07;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                }
                span{
                    display: flex;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                    color: #FF5F07;
                }
            }
        }
        .contactus-form-wrp{
            padding-top: 2rem;
            .contactus-form-hd{
                padding-bottom: 2rem;
                align-items: center;
                display: flex;
                flex-direction: column;
                h1{
                    font-weight: 700;
                    font-size: 1.5rem;
                    line-height: 18px;
                    text-align: center;
                    color: #000000;
                    padding-bottom: 0.8rem;
                }
                h3{
                    font-weight: 300;
                    font-size: 1.1rem;
                    line-height: 18px;
                    text-align: center;
                    color: #000000;
                    max-width: 220px;
                }

            }
            .contactus-form-in{
                // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                padding: 0px 1.8rem 20px;
                // margin-bottom: 10rem;
            }
        }
    }
}

@media(max-width: 767px){
    .contactus-form-hd{
        h1{
            font-size: 1.5rem !important;
        }
    } 
}

.contactus_bg{
    background-image: url(../TuneUp/home_bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-attachment: fixed;
  min-height: 100vh;
}
@media(max-width: 767px){
    .contactus_bg{
      background-image: url(../../images/homebg.svg);
      background-size: 100%;
      background-position: bottom;
      min-height: 95vh !important;
      background-attachment: revert !important;
    }
  }
// .contactus-form{
//     margin-bottom: 270px;
// }
// @media(max-width: 567px){
//     .contactus-form{
//         margin-bottom: 0px;
//     }
// }
@media(min-width: 280px) and (max-width: 375px){
    .contactus_bg{
        // height: 120vh;
        // margin-bottom: 200px;
    }
}
// @media(min-width: 769px) and (max-width: 912px){
//     .contactus_bg{
//         height: 100vh;
//     }
// }
@media(min-width: 415px) and (max-width: 540px){
    // .contactus_bg{
    //     height: 120vh;
    // }
}

.lbl-orderid{
    font-weight: 400 !important;
    font-size: 1rem !important;
    color: #000000 !important;
}

.lbl-message{
    font-weight: 400 !important;
    font-size: 1rem !important;
    color: #000000 !important;
    height: 16px !important;
}

.contact-us-textarea{
    height: 12rem !important;
}

.btn-contact-us{
    font-weight: 300 !important;
    font-size: 1.4rem !important;
    // text-transform: none !important;
    border-radius: 10px !important;
}

.contact-header-text{
    
    margin: 0 auto;
    display: flex;
    p{
        padding-right: 4rem;
        font-weight: 300 !important;
        font-size: 1.5rem !important;
    }
}
