@font-face {
  font-family: 'Dubai';
  src: url('../../fonts/Dubai-Regular.ttf') format('truetype');
  /* Add additional font styles and weights if you have separate font files */
}
*,*::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-size: 14px;
}
body {
  font-family: Dubai;
  font-size: 1rem;
  font-style: normal;
  line-height: 1.3;    
  color: #000000;
}
// img{
//   max-width: 80vw;
// }
.landing_main {
width: 100%;
overflow-x: scroll;
display: flex;
flex-direction: column;
scroll-snap-type: y mandatory;
overflow-x: hidden;
}
.home_btn{
  padding-left: 20px;
  padding-right: 20px;
}
.landing_main > section {
  min-height: 100vh;
  scroll-snap-align: start;  
  background: url(../../images/bg.jpg) no-repeat;
  background-position: center bottom;
  background-size: cover;
  padding: 1.429rem 0;
}
.home-msg{
  font-size: 35px;
}
a{
  text-decoration: none;
}
section h2{
  padding-top: 2.143rem;
  font-weight: 500;
  font-size: 2.557rem;
  margin-bottom: 0.71428rem;
  text-align: center;
  color: #ff5f07;
}
.dn {
  display: none;
}

.btn-update-ok{
  height: 2.5rem !important;
}
.btn {
  align-items: center;
  background: #ff5f07;
  font-family: 'Dubai';
  font-style: normal;
  /* padding-left: 0.5rem;
  padding-right: 0.5rem; */
  border: 0.071rem solid #ff5f07;
  border-radius: 0.63rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.375rem;
  font-weight: 300;
  height: 3rem;
  justify-content: center;
  letter-spacing: 0.071rem;
  line-height: 3.429rem;
  text-align: center;
  // text-transform: uppercase;
  width: 100%;
  color: #fff;
  text-decoration: none;
}
.delete-btn1{
  height: 35px !important;
  width: 80% !important;
  font-size: 1.2rem !important;
}

.cancel-btn{
  width: 5rem !important;
  height: 2.5rem !important;
  font-size: 1.2rem;
}
.success-btn{
  width: 50% !important;
  margin: 0 auto !important;
  height: 40px !important;
  font-size: 1.2rem;
}

.del-btn{
  width: 18% !important;
  height: 40px !important;
  font-size: 1.2rem !important;
}

/*  */
@media only screen and (min-width: 375px) and (max-width: 600px) {
  body {
    // font-family: Dubai, sans-serif;
    font-size: 14px;
    line-height: 1.3;    
    color: #000000;
  }
  
}
@media only screen and (min-width: 767px) and (max-width: 1180px) {
  section h2{
    font-size: 3rem;
    padding-top: 3.243rem;
  }
  
}
@media only screen and (min-width: 1181px) {
  section h2{
    font-size: 3.957rem;
  }
  .landing_main > section {
    height: 700px;
  }
}
/* Header */
.landing_header{
  height: 4.2857rem;
  padding: 0 1.0714rem;
  position: fixed;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
  top: 0;
  transition: 0.1s ease-in top;
}
.landing_header.hide {
  top: -4.2857rem;
  transition: 0.1s ease-in top;
}
.landing_header.show {
  top: 0;
  transition: 0.1s ease-in top;
}
.header {
  display: flex !important;
  justify-content: space-between;
  height: 4.2857rem;
  align-items: center;
  position: relative;
}
.hd-logo a {
  display: block !important;
}
.hd-logo a img {
  max-width: 11.428571428571429rem;
}
.hd-nav .nav-icon{
  background: transparent;
  border: 0;
}
.nav-data {
  transition: left 0.3s ease-in;
  position: fixed;
  left: 100%;
  right: 0;
  top: 4.285714285714286rem;
  bottom: 0;
  background: #4a4949;
  width: 100%;
}
.nav-data.show {
  left: 0;
}
.nav-data ul {
  padding-left: 25%;
  list-style: none;
  padding-top: 1.4285714285714286rem;
}
.nav-data ul li {
  border-top: 0.071rem solid #f1f1f1;
  text-align: left;
}
.nav-data ul li:first-child {
  border-top: 0;
}
.nav-data ul li a{
  color: #f1f1f1;
  text-decoration: none;
  font-size: 1.1428571428571428rem;
  padding: 0.7142857142857143rem 1.0714285714285714rem;
  display: block;
}


@media only screen and (min-width: 767px) and (max-width: 1180px) {
  .header {
    height: 5rem;
  }
  .landing_header {
    height: 5rem;
  }
  .landing_header.hide {
    top: -5rem;
  }
  .hd-logo a img {
    max-width: 13.428571rem;
  }
  .nav-data ul{
    padding-left: 15%;
  }
  .nav-data ul li a {
    font-size: 1.5rem;
    padding: 1rem;
  }
}
@media only screen and (min-width: 1181px) {
  .header {
    justify-content: end;
  }
  .landing_header .hd-logo {
    display: none;
  }
  .hd-nav .nav-icon span {
    display: block;
    border: 1px solid #000;
    width: 50px;
    height: 50px;
    background: url(../../images/bg.jpg) no-repeat;
  }
  .landing_header{
    position: fixed;
    background-color: transparent;
    
  }
  .landing_header.show{
    top: 1.0714rem;
  }
  .landing_header.hide{
    top: 1.0714rem;
  }
  .nav-data {
    transition: all 0.3s ease-in;
    position: fixed;
    left: 100%;
    right: 1.0714rem;
    bottom: auto;
    width: 300px;
    top: 4.785714rem;
  }
  .nav-data.show{
    left: auto;
  }
  .nav-data ul{
    padding-left: 10%;
    padding-bottom: 1.428rem;
  }
}
/* Banner Section */
.landing_main > section.banner {
  background: url(../../images/home-banner-app.jpg) no-repeat;
  background-position: center bottom;
  background-size: 50rem;
  min-height: 87vh;
}

.banner-section{ 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(90vh - 40px);
  margin: 0;
  padding-bottom: 7.142857142857143rem;
}
.banner-section img {
  max-width: 27.143rem;
  display: block;
  margin: 0 auto;
  width: 80%;
}
.banner-section h1 {
  margin: 0 auto 2.528571rem;
}
.banner-section .btn {
  max-width: 18.571428571428573rem;
}
@media only screen and (min-width: 767px) and (max-width: 1180px) {
  .landing_main > section.banner {
    background-size: contain;
  }
  .banner-section img {
    max-width: 40.143rem;
  }
  .banner-section .btn {
    max-width: 21.071429rem;
    height: 4.229rem;
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 1181px) {
  .landing_main > section.banner {
    background: url(../../images/bg.jpg) no-repeat;
    background-position: center bottom;
    background-size: cover;
    padding: 0;
    min-height: 100vh;
  }
  .banner-section{
    background: url(../../images/home-banner.jpg) no-repeat;
    background-position: center bottom;
    background-size: cover;
    padding-bottom: 0;
    justify-content: start;
    padding-top: 130px;
    min-height: 100vh;
    height: 700px;
  }
  .banner-section img {
    max-width: 47.143rem;
  }
  .banner-section .btn {
    max-width: 22.571429rem;
    font-size: 1.2rem;
    height: 4.029rem;
  }
}
@media only screen and (min-width: 1181px) and (max-width: 1600px) {
  .landing_main > section.banner {
    background-size: cover;
    padding: 0;
  }
  .banner-section{
    padding-top: 70px;
    
  }
}

/* our-services */
.our-services h3 {
  margin-top: 3.7rem;
}
.our-services h3 img {
  width: 90%;
  max-width: 31.428571428571427rem;
  margin: 0 auto;
  display: block;
}

.our-services-list{
  margin: 0 -0.35714285714285715rem;
  height: calc(100vh - 20.5rem);  /* 100px */
  display: flex;
  padding: 0 0.7rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.our-services-list ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.7142857142857143rem; /*0.714*/
  row-gap: 3rem;
  align-items: center;
  justify-content: center;
}
.our-services-list li {
  flex: 0 0 calc(33.33% - 0.7142857142857143rem);
  text-align: center;
}
.our-services-list li img {
  width: 4.285714rem;
}
.our-services-list li h3 {
  font-size: 0.914286rem;
  background: rgba(255, 95, 7, 0.85);
  text-align: center;
  padding: 0 0.3rem;
  font-weight: 400;
  color: #fff;
  margin-top: 0.557143rem;
  height: 4.828571rem;
  align-items: center;
  justify-content: center;
  display: flex;
}
@media only screen and (min-width: 767px) and (max-width: 1180px) {
  .our-services h3 {
    margin-top: 5.8rem;
  }
  .our-services h3 img {
    max-width: 45rem;
  }
  .our-services-list ul{
    row-gap: 4.142857rem;
  }
  .our-services-list li h3{
    font-size: 1.3rem;
    height: 4.728571rem;
  }
  .our-services-list li img{
    width: 6.5rem;
  }
  .our-services-list{
    margin: 8rem 0.042857rem 1rem;
    height: auto;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (min-width: 1181px) {
  /*  */
  .our-services h3{
    margin-top: 3.5rem;
  }
  .our-services h3 img {
    max-width: 45rem;
  }
  .our-services-list ul{
    gap: 1.5rem;
  }
  .our-services-list li{
    flex-basis: calc(16.6666% - 1.5rem);
  }
  .our-services-list li img{
    width: 5.885714rem;
  }
  .our-services-list li h3{
    font-size: 1.4rem;
    text-align: center;
    padding: 0 0;
    margin-top: 1.157rem;
    height: 6.828571rem;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 14rem;
    font-weight: 400;
    color: #fff;
  }
  .our-services-list{
    height: calc(100vh - 19.529rem);
  }
}
@media only screen and (min-width: 1181px) and (max-width: 1315px) {
  .our-services-list li h3{
    padding: 0;
    width: 12.3rem;
  }
  
}

/* Our Mission */
.our-mission {
  padding-bottom: 2.142857142857143rem;
}
.our-mission-content{
padding: 0 0.571rem;
}




.our-mission-content ul li{
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 100px);
  align-items: center;
  padding-bottom: 30px;
}
.our-mission-content ul li::before {
  content: '';
  background: url(../../images/Mission_mechanics.svg) no-repeat;
  width: 100px;
  height: 78px;
  position: absolute;
  /* top: -100px; */
  right: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 68%;
  background-position: center;
}
.our-mission-content ul li:nth-child(2) {
  width: 100%;
  padding-left: 100px;
}
.our-mission-content ul li:nth-child(2)::before {
  display: none;
}
.our-mission-content ul li:nth-child(2)::after {
  content: '';
  background: url(../../images/Mission_Bikers.svg) no-repeat;
  background-size: 85%;
  background-position: center;
  width: 100px;
  height: 97px;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.our-mission-content ul li:last-child:before {
  content: '';
  background: url(../../images/Mission_Community.svg) no-repeat;
  background-size: 85%;
  background-position: center;
  width: 100px;
  height: 100px;
}


.our-mission-content ul {
  list-style: none;
}
.our-mission-line {
  padding-top: 30px;
}
.our-mission-content ul .dt-wrp h3 {
  font-size: 1.343rem;
  margin-bottom: 0.714rem;
  text-align: center;
}
.our-mission-content ul .dt-wrp p {
  font-size: 1rem;
}
.our-mission-content ul li:first-child .dt-wrp h3 {
  color: #ee6045;
}
.our-mission-content ul li+li+li .dt-wrp h3 {
  color: #507c76;
}
@media only screen and (min-width: 767px) and (max-width: 1180px) {
  .our-mission-content ul .dt-wrp h3{
    font-size: 2.143rem;
  }
  .our-mission-content ul .dt-wrp p {
    font-size: 1.5rem;
  }
  .our-mission-content ul li {
    padding-bottom: 4rem;
    width: calc(100% - 150px);
  }
  .our-mission-content ul li::before{
    right: -150px;
    background-size: 68%;
    width: 150px;
    height: 150px;
  }
  .our-mission-content ul li:nth-child(2){
    padding-left: 150px;
  }
  .our-mission-content ul li:nth-child(2)::after {
    left: 0;
    background-size: 78%;
    width: 150px;
    height: 150px;
  }
  .our-mission-content ul li:last-child:before {
    right: -150px;
    background-size: 88%;
    width: 150px;
    height: 150px;
  }
  .our-mission-line {
    max-width: 600px;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1181px) {

  .mission-head {
    margin-bottom: -50px;
    margin-top: 80px;
  }
  .our-mission-line {
    background: url(../../images/Mission_separating_line.png) no-repeat;
    background-position: bottom;
    background-size: 1400px;
    height: 300px;
}
.our-mission-dt{
  /* height: calc(100vh - 144px); */
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
  .our-mission-content ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding-top: 80px;
    align-items: start;
    max-width: 1200px;
    margin: 0 auto;
  }
  .our-mission-content ul li {
    width: 350px;
    padding: 0;
  }
  .dt-wrp {
    width: 350px;
    margin: 0 auto;
  }
  .our-mission-content ul li:nth-child(2){
    padding: 0;
    margin-top: -163px;
    align-items: start;
  }
  .our-mission-content ul li::before {
    width: 78px;
    height: 78px;
    position: absolute;
    top: -91px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 100%;
    background-position: center;
    left: calc(50% - 39px);
  }
  .our-mission-content ul li:nth-child(2)::after {
    background-size: 100%;
    background-position: center;
    width: 97px;
    height: 97px;
    position: absolute;
    bottom: -100px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: calc(50% - 47.5px);
  }
  .our-mission-content ul li:last-child:before {
    background-size: 100%;
    top: -100px;
  }
  .our-mission-content ul .dt-wrp h3 {
    text-align: center;
    font-size: 1.743rem;
  }
  .our-mission-content ul .dt-wrp p {
    font-size: 1.3rem;
  }
}


/* how-it-works */
.how-it-works{
  padding: 1.429rem 0;
}
.how-it-works-content{
  margin: 0 1.143rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}
section .how-it-works-content h2 {
  font-size: 2.257rem;
}

.how-it-works-content .how-it-works-content-list {
  list-style: none;
  background: url(../../images/Riders_howitworks_APP_separatingarrow.png) no-repeat;
  background-size: 8.557rem 41.057rem;
  padding-bottom: 2.143rem;
  padding-left: 1.429rem;
  padding-top: 1.071rem;
  max-width: 44.286rem;
  margin: 0 auto;
}
.how-it-works-content .how-it-works-content-list-li {
  padding-bottom: 1.429rem;
  padding-top: 0.357;
}
.how-it-works-content .how-it-works-content-list-li .how-dt {
  overflow: hidden;
}
.how-it-works-content .how-it-works-content-list-li .how-dt-cont{
  margin-left: 6.429rem;
  display: flex;
  height: 6.429rem;
  align-items: center;
  padding-left: 2.143rem;
}
.how-it-works-content .how-it-works-content-list-li .how-dt-ic {
  background: url(../../images/wheel-bikers.png) no-repeat;
  background-size: contain;
  width: 6.429rem;
  height: 6.429rem;
  font-weight: 700;
  color: #ff5f07;
  font-size: 0.929rem;
  float: left;
}
.how-it-works-content .how-it-works-content-list-li .how-dt-ic span {
  display: flex;
  width: 6.429rem;
  height: 6.429rem;
  align-items: center;
  justify-content: center;
}
.how-it-works-content .how-it-works-content-list-li .how-dt h3 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #4a4949;
}
.how-it-works-content .how-it-works-content-list-li .how-dt ul {
  padding-left: 1.429rem;
}
@media only screen and (min-width: 767px) and (max-width: 1180px) {
  .how-it-works-content .how-it-works-content-list{
    background-size: 12.357rem 59.057rem;
    padding-bottom: 3.743rem;
    padding-left: 1.429rem;
    padding-top: 1.771rem;
    max-width: 40.386rem;
    margin: 0 auto;
  }
  .how-it-works-content .how-it-works-content-list-li{
    padding-top: 0.357em;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-ic{
    width: 9.429rem;
    height: 9.429rem;
    font-size: 1.429rem;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-ic span{
    width: 9.429rem;
    height: 9.429rem;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-cont{
    margin-left: 9.429rem;
    display: flex;
    height: 9.429rem;
    align-items: center;
    padding-left: 3.543rem;
  }
  section .how-it-works-content h2 {
    font-size: 3rem;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt h3{
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1181px) {
  .how-it-works-content-dt{
    min-height: calc(100vh - 330px);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .how-it-works-content .how-it-works-content-list{
    background: url(../../images/Riders_How-it-works_Separating_arrow.png) no-repeat;
    background-size: 1270px 260px;
    padding: 20px 30px 30px 20px;
    max-width: initial;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .how-it-works-content .how-it-works-content-list-li {
    padding: 0 1rem;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: inherit;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-ic {
    width: 15.429rem;
    height: 15.429rem;
    float: none;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-ic span {
    position: absolute;
    bottom: -70px;
    width: auto;
    height: auto;
    left: 0;
    right: 0;
    z-index: 5;
    font-size: 2rem;
    font-weight: 400;
    color: #4a4949;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-cont {
    margin-left: 0;
    padding-left: 0;
    height: auto;
    position: absolute;
    top: 12%;
    left: 12%;
    right: 12%;
    bottom: 12%;
    text-align: center;
    justify-content: center;
  }
  section .how-it-works-content h2 {
    font-size: 3.957rem;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #ff5f07;
  }
}

/* how-it-works RideDocs Apps*/
.docs-how-it-works-app .how-it-works-content{
  margin: 0 1.143rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list {
  list-style: none;
  background: url(../../images/RideDocs_Howitworks_APP_separatingarrow.png) no-repeat;
  background-size: contain;
  background-size: 9.314rem 51.129rem;
  padding-bottom: 2.143rem;
  padding-left: 1.429rem;
  padding-top: 1.286rem;
  max-width: 44.286rem;
  margin: 0 auto;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li {
  padding-bottom: 1.429rem;
  padding-top: 0.357rem;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt {
  overflow: hidden;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-cont{
  margin-left: 6.429rem;
  display: flex;
  height: 6.429rem;
  align-items: center;
  padding-left: 2.143rem;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic {
  background: url(../../images/wheel-bikers.png) no-repeat;
  background-size: contain;
  width: 6.429rem;
  height: 6.429rem;
  font-weight: 700;
  color: #ff5f07;
  font-size: 0.929rem;
  float: left;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic span {
  display: flex;
  width: 6.429rem;
  height: 6.429rem;
  align-items: center;
  justify-content: center;
  font-size: 0;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic span::before {
  content: attr(data-content);
  display: block;
  width: 4.286rem;
  height: 4.286rem;
  background: rgba(255, 95, 7, 0.97);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #ffe4d5;
  font-size: 1.143rem;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt h3 {
  font-size: 1.2rem;
  font-weight: 700;
  color: #4a4949;
}
.docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt ul {
  padding-left: 1.429rem;
}
@media only screen and (min-width: 767px) and (max-width: 1180px) {
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic {
    width: 9.429rem;
    height: 9.429rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic span{
    width: 9.429rem;
    height: 9.429rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic span::before{
    width: 7.286rem;
    height: 7.286rem;
    font-size: 2.243rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list{
    background-size: 12.914rem 70.429rem;
    padding-bottom: 2.543rem;
    padding-left: 1.429rem;
    padding-top: 1.786rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-cont{
    height: 9.429rem;
    margin-left: 9.429rem;
    padding-left: 2.843rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt h3{
    font-size: 1.4rem;
  }
}
@media only screen and (min-width: 1181px) {
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list {
    background: url(../../images/RideDocs_howitworks_separatingarrow.png) no-repeat;
    background-size: 1235px 160px;
    max-width: initial;
    padding: 20px 30px 30px 20px;
    margin: 0 auto;
    background-position: 0 163px;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic {
    background: url(../../images/wheel-bubble.png) no-repeat;
    background-size: 170px;
    width: 170px;
    height: 250px;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic span{
    width: auto;
    height: auto;
    font-size: 2rem;
    position: absolute;
    top: 20px;
    align-items: flex-start;
    bottom: auto;
    font-weight: 300;
    color: #fff;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic span::before {
    display: none;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-cont {
    margin: 0;
    padding: 0;
    position: static;
    height: 70px;
    width: 170px;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li:nth-child(even) {
    position: relative;
    top: 133px;
  }

}
/* Services Availability */
.servicesav{
  padding: 1.429rem 0;
}
section.servicesav-content h2 {
  padding-top: 4.143rem;
}
.servicesav-map{
  margin: 0 auto;
  display: flex;
  min-height: calc(100vh - 110px);
  flex-direction: column;
  justify-content: center;
}
.servicesav-map img {
  display: block;
  margin: 0 auto;
  max-width: 96%;
}
.servicesav-map-av-lst{
  padding: 3rem 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 6rem;
  font-size: 1.5rem;
}
.servicesav-map-av-lst-cr{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 1.071rem;
}
.servicesav-map-av-lst-cr::before{
  content: '';
  display: block;
  width: 0.857rem;
  height: 0.857rem;
  background-color: #ff5f07;
  border-radius: 0.857rem;
  margin-right: 0.714rem;
}
.servicesav-map-cm-soon-txt{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.servicesav-map-cm-soon-txt::before{
  content: '';
  display: block;
  width: 0.857rem;
  height: 0.857rem;
  background-color: #ffe4d5;
  border: 0.143rem solid #ff5f07;
  border-radius: 0.857rem;
  margin-right: 0.714rem;
}
.servicesav-map-cm-soon-loc{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.servicesav-map-cm-soon-loc::before{
  content: '';
  display: block;
  width: 0.571rem;
  height: 0.571rem;
  background-color: #4a4949;
  margin-right: 0.714rem;
  margin-left: 1.571rem;
}
// Price

section#Price {
  min-height: 100vh !important;
  height: auto !important;
}

.price_tab {
  font-family: "Dubai";
  border-collapse: collapse;
  width: 100%;
  height: max-content !important;
  display: flex;
  justify-content: center;
}

.price-content {
  text-align: center;
  margin-bottom: 10px;
}

.price_tab th, .price_tab td {
  border: 1px solid #dddddd;
  color: #4a4949;
  padding: 8px;
  text-align: left;
  font-size: 1.2rem; /* Increased font size */
  padding: 12px; /* Increased padding */
}

.resource {
  height: unset !important;
}

.resContainer {
  // display: flex;
  //   flex-wrap: wrap;
  //   justify-content: space-evenly;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 20px;

    a {
      margin-right: auto;
      margin-left: auto;
    }
}

.resImgItem {
  width: 200px;
  margin-right: auto;
  margin-left: auto;
  // height: 200px;
  padding: 10px 10px;
  border: 1px solid #4a4949;
  border-radius: 10px;

}

.price-subhead {
  font-size: 1.3rem;
  color: #4a4949;
  text-align: center;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  padding-bottom: 10px;
}

.price-footer {
  text-align: center;
  margin-top: 20px;
  font-size: 1.3rem;
  color: #4a4949;
}


.mission-head {
  display: block;
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 500;
}

@media only screen and (min-width: 767px) and (max-width: 1180px) {
  .servicesav-map-av-lst-cr{
    font-size: 1.7rem;
  }
  .servicesav-map-cm-soon-txt{
    font-size: 1.7rem;
  }
  .servicesav-map-cm-soon-loc{
    font-size: 1.2rem;
  }
  .servicesav-map{
    min-height: calc(100vh - 130px);
  }
}
@media only screen and (min-width: 1181px) {
  .servicesav-content {
    position: relative;
  }
  .servicesav-map img {
    max-width: 60%;
  }
  .servicesav-map-av-lst {
    position: absolute;
    right: 0;
    font-size: 1.4rem;
    width: auto;
  }
}

/* Contact us */
.contactus{
  padding: 1.429rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.landing_main>section.contactus {
  padding: 1.429rem 0 0;
}
.contactus-content {
  padding: 0 1rem;
}
.contactus-content h3 {
  font-size: 1.271rem;
  text-align: center;
  color: #4a4949;
  font-weight: 500;
  margin: 7rem auto 2.143rem;
  max-width: 20rem;
}
.contactus-content .contactus-mail a{
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 1.5rem;
}
.contactus-content .contactus-mail img{
  max-width: 2.443rem;
}
.contactus-content .contactus-mail span{
  font-size: 1.271rem;
  font-weight: 500;
  display: inline-block;
  margin-left: 0.714rem;
  color:#4a4949;
}



.footer{
  background-color: #4a4949;
  padding: 1.429rem 0 0;
}
.footer-in{
  padding: 0 1.429rem;
  display: flex;
  justify-content: center;
  text-align: center;
}
.footer-menu a,
.footer-menu span{
  color: #fff;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
}
.footer-menu > li {
  padding: 0.214rem 0;
}
.footer-social {
  list-style: none;
  display: flex;
  flex-direction: row;
  column-gap: 1.071rem;
  padding-top: 0.714rem;
}
.footer-social li a img {
  max-width: 3.171rem;
}
.footer-btm{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 1.429rem 1.5rem;
}
.footer-btm .footer-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-btm .footer-menu li {
  display: inline-block;
}
.footer-btm .footer-menu li a {
  padding: 0 0.5rem;
}
.footer p {
  text-align: center;
  color: #fff;
  height: 50px;
  align-items: center;
  display: flex;
  font-size: 1rem;
}
@media only screen and (min-width: 767px) and (max-width: 1180px) {
  .contactus-content h3 {
    font-size: 1.871rem;
    max-width: 32rem;
  }
  .contactus-content .contactus-mail a {
    margin-top: 3.5rem;
  }
  .contactus-content .contactus-mail img {
    max-width: 3.843rem;
  }
  .contactus-content .contactus-mail span {
    font-size: 1.871rem;
  }
  .footer-btm{
    padding: 0 1.429rem;
  }
  .footer p {
    font-size: 1.2rem;
  }
  .footer-menu a,
  .footer-menu span{
    font-size: 1.2rem;
  }
  .footer-social li a img {
    max-width: 4.071rem;
  }
}
@media only screen and (min-width: 1181px) {
  .contactus-content h3 {
    font-weight: 400;
    font-size: 2.171rem;
    text-align: center;
    color: #4a4949;
    margin: 3.8rem auto 2.143rem;
    max-width: 47rem;
    width: 100%;
  }
  .footer p {
    font-size: 1.2rem;
  }
  .footer-btm{
    padding: 0 1.429rem;
  }
  .footer-menu a,
  .footer-menu span{
    font-size: 1.2rem;
  }
  .footer-social {
    column-gap: 2.071rem;
  }
  .footer-social li a img {
    max-width: 4.071rem;
  }
  .contactus-content .contactus-mail {
    margin-top: 4.5rem;
  }
  .contactus-content .contactus-mail img {
    max-width: 3.643rem;
  }
  .contactus-content .contactus-mail span {
    font-weight: 400;
    font-size: 2.171rem;
  }

  
  .price_tab th, .price_tab td {
    font-size: 1.6rem !important;
  }

  .price-subhead, .price-footer {
    font-size: 1.7rem;
  }
  
}



/* Only 1366 */
@media only screen and (min-width: 1365px) and (max-width: 1367px) {
  section h2{
    font-size: 3.757rem;
  }
  .landing_main > section {
    height: 610px;
  }
  
  .landing_main > section.banner {
    background: url(../../images/bg.jpg) no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 610px;
  }
  .banner-section{
    background: url(../../images/home-banner.jpg) no-repeat;
    background-position: center bottom;
    background-size: cover;
    padding-bottom: 0;
    justify-content: start;
    padding-top: 50px;
    min-height: 100vh;
    height: 610px;
  }
  .banner-section img {
    max-width: 37.143rem;
  }
  .banner-section .btn {
    max-width: 20.571429rem;
    font-size: 1.1rem;
    height: 3.629rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 766px) and (orientation: landscape) {
  .landing_main > section {
    min-height: 520px;
  }
  .landing_main > section.banner {
    min-height: 450px;
  }
  .our-services-list{
    height: auto;
  }

}
/* landscape only */
@media screen and (min-width: 767px) and (max-width: 1180px) and (orientation: landscape) {
  .landing_main > section.banner {
    background-size: 84.3rem;
  }
  .servicesav-map img {
    max-width: 60%;
  }
  .banner-section{
    padding-bottom: 17.342857rem;
  }

  .our-services h3 {
    margin-top: 1.8rem;
  }
  .our-services h3 img {
    max-width: 42rem;
  }
  .our-services-list ul {
    row-gap: 3.0rem;
  }
  .our-services-list li {
    flex-basis: calc(33.33% - 2.7142857142857143rem);
  }

  .our-mission-content ul .dt-wrp h3 {
    font-size: 1.943rem;
    text-align: center;
  }
  .our-mission-content ul .dt-wrp p {
    font-size: 1.2rem;
  }
  .our-mission-content ul li {
    padding-bottom: 4rem;
    width: calc(100% - 120px);
  }
  .our-mission-content ul li::before{
    right: -120px;
    background-size: 68%;
    width: 120px;
    height: 120px;
  }
  .our-mission-content ul li:nth-child(2){
    padding-left: 120px;
  }
  .our-mission-content ul li:nth-child(2)::after {
    left: 0;
    background-size: 70%;
    width: 120px;
    height: 120px;
  }
  .our-mission-content ul li:last-child:before {
    right: -120px;
    background-size: 78%;
    width: 120px;
    height: 120px;
  }
  .our-mission-line {
    max-width: 600px;
    margin: 0 auto;
  }

  /* Riders */
  .how-it-works-content .how-it-works-content-list {
    background-size: 9.357rem 43.657rem;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-ic {
    width: 6.429rem;
    height: 6.429rem;
    font-size: 1.229rem;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-ic span {
    width: 6.429rem;
    height: 6.429rem;
  }
  .how-it-works-content .how-it-works-content-list-li .how-dt-cont {
    margin-left: 6.429rem;
    height: 6.429rem;
    padding-left: 2.543rem;
  }

  /* RideDocs */
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list {
    background-size: 9.014rem 49.229rem;
    padding-top: 1.386rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li{
    padding-bottom: 1.629rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic{
    width: 5.829rem;
    height: 5.829rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic span {
    width: 5.829rem;
    height: 5.829rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-ic span::before {
    width: 4.986rem;
    height: 4.986rem;
    font-size: 1.843rem;
  }
  .docs-how-it-works-app .how-it-works-content .how-it-works-content-list-li .how-dt-cont {
    height: 5.829rem;
    margin-left: 5.829rem;
    padding-left: 2.843rem;
  }
}

@media screen and (min-width: 500px) {
  .resContainer {
    width: 50%;
  }
  
}

@media screen and (max-width: 500px) {
  
  .resContainer {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    a {
      padding: 0px;
      margin: 0px;
    }
    // grid-template-columns: repeat(auto-fit, 1fr);
  }
  .resImgItem {
    position: relative;
    // box-shadow: inset 0px 0px 20px 0px rgba(0, 0, 0, 0.432);
    z-index: 1;
    margin-right: auto;
    margin-left: auto;
    width: 190px;
    padding: 10px;
    // height: 150px;
    flex: 0 0;
  }
}

// .price_tab th {
//   background-color: #f2f2f2;
// }

// .price_tab tr:nth-child(even) {
//   background-color: #f2f2f2;
// }

// .price_tab tr:hover {
//   background-color: #ddd;
// }
