@import './../../scss/colors.scss';

.auth-wrp {
  .auth-hd{
    padding-top: 3.19rem;
  }
  h1 {
    padding-bottom: 6px;
    font-size: 26px;
    line-height: 1.5;
    text-align: center;
  }
  .auth-social-log-wrp{
    // padding-top: 10px;
    ul {
      display: flex;
      justify-content: center;
      // width: 96px;
      margin: 0 auto;
      li {
        display: flex;
        a {
          background: $white;
          border: 1px solid rgba(210, 210, 210, 0.48);
          border-radius: 32px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.3s ease-in;
          &:hover{
            transition: 0.3s ease-in;
            background: rgba($white, .15);
          }
          img{
            max-width: 26px;
          }
        }
      }
    }
  }

  .auth-or-cont{
    // margin-left: 20%;
    // margin-right: 20%;
    margin-top: 25px;
    position: relative;
    margin-bottom: 39px;
    &::before{
      content: "";
      border-top: 1px solid #FF5F07;
      display: block;
    }
    p{
      position: absolute;
      top: -9px;
      left: calc(50% - 75px);
      width: 150px;
      display: flex;
      justify-content: center;
      margin:  0 auto;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 18px;
      color: #000000;
      background-color: white;
    }
  }

  @media(max-width: 915px){
    .auth-or-cont{
      margin-left: 5%;
      margin-right: 5%;
    }
  }

  .auth-default-log-wrp{
    padding: 0 20px;
    // margin-left: 20%;
    // margin-right: 20%;
  }
  @media(max-width: 915px){
    .auth-default-log-wrp{
      margin-left: 0%;
      margin-right: 0%;
    }
  }
  .alredy-signin{
    // padding-top: 10px;
    // text-align: center;
    padding-bottom: 15px;
    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 18px;
      color: $black
    }
  }

}

.alredy-signup{
  // padding-top: 10px;
  text-align: center;
  padding-bottom: 15px;
  p {
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    color: #000000
  }
}

// REg popup
.reg-pop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, .65);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .reg-popin{
    background-color: $white;
    padding: 25px;
    position: relative;
    border-radius: 4px;
    text-align: center;
    .reg-close{
      position: absolute;
      right: -16px;
      top: -16px;
      border: 1px solid #222;
      width: 32px;
      height: 32px;
      border-radius: 32px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .reg-pophd{
      p{
        margin-top: 15px;
      }
    }
  }
}


// Forgot Password
.forgot-form-wrp{
  min-height: calc(80vh - 320px);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
 
  .auth-hd{
    width: 100%;
    text-align: center;
    h3{
      padding-top: 10px;
      font-size: 12px;
    }
  }
  .auth-default-log-wrp{
    padding-top: 30px;
    width: 100%;
  }
}
// .kep-login-facebook-css  {
//   font-family: Helvetica,sans-serif;
//   font-weight: 400;
//   //-webkit-font-smoothing: antialiased; 
//   color: #fff;	
//   cursor: pointer;	
//   display: inline-block;	
//   font-size: 17px;	
//   //text-size-adjust: 10px;
//   text-decoration:none;	
//   //text-transform: uppercase;		
//   transition: background-color .3s,border-color .3s;	
//   background-color: #4c69ba;		
//   border: calc(0.06887vw + 0.67769px)solid #4c69ba;
//   padding: 8px;
//   border-radius: 50px;
//   margin-left: 4px;
// }


@media(min-width: 768px) and (max-width: 820px){
  .forgot-form-wrp{
    min-height: 0px;
    margin-top: 20px;
  }
}
@media(min-width: 821px) and (max-width: 912px){
  .forgot-form-wrp{
    min-height: 0px;
    margin-top: 80px;
  }
}
// Profile Information
.profile-wrp{
  padding: 20px 20px 0;
  .profile-user-sec-wrp{
    background: #FFFFFF;
    border-radius: 6px;
    height: 90px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .pro-lft{
    width: 50px;
    height: 50px;
    background-color: #FFF2EB;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pro-right{
    padding-left: 20px;
    h3{
      padding-bottom: 3px;
      font-weight: 700;
    }
    p{
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }
  }
  .profile-user-list-wrp{
    padding-top: 20px;
    ul {
      padding: 0;
      background-color: $white;
      border-radius: 4px;
      li {
        list-style-type: none;
        &:last-child {
          a {
            border-bottom: 0;
          }
        }
        &:hover{
          a {
            background-color: #fbfbfb;
            transition: 0.3s ease-in background-color;
          }
        }
        a {
          display: flex;
          align-items: center;
          padding: 20px;
          border-bottom: 1px solid #F3F3F3;
          transition: 0.3s ease-in background-color;
          p{
            color: $black;
            padding-left: 15px;
            font-size: 16px;
          }
        }
      }
    }
  }
}


// .profile-info-sec-wrp{
//   padding-top: 20px;
// }


// Reset Password
.reset-success-wrp{
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 140px);
  h2{
    padding-bottom: 20px;
  }
  .form-group{
    width: 60%;
  }
}


@media (max-width: 599px) {
  .auth-wrp {
    .auth-hd{
      padding-top: 3rem;
      padding-bottom: 1rem;
      margin-left: 20%;
      margin-right: 20%;
    }
    .forgot-hd{
      padding-top: 8rem !important;
      padding-bottom: 4rem !important;
    }
    h1 {
      // padding-bottom: 6px;
      font-size: 1.5rem;
      line-height: 1.5;
    }
    .auth-social-log-wrp{
      // padding-top: 10px;
      ul {
        display: flex;
        justify-content: center;
        // width: 96px;
        margin: 0 auto;
        li {
          display: flex;
          a {
            background: $white;
            border: 1px solid rgba(210, 210, 210, 0.48);
            border-radius: 32px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s ease-in;
            &:hover{
              transition: 0.3s ease-in;
              background: rgba($white, .15);
            }
            img{
              max-width: 26px;
            }
          }
        }
      }
    }
  
    .auth-or-cont{
      margin-top: 2rem;
      position: relative;
      margin-bottom: 2rem;
      &::before{
        content: "";
        border-top: 1px solid #ff5f06;
        display: block;
      }
      p{
        position: absolute;
        top: -9px;
        left: calc(50% - 75px);
        width: 150px;
        display: flex;
        justify-content: center;
        margin:  0 auto;
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        line-height: 18px;
        color: #000000;
        // background-color: $primary-light-bg;
      }
    }
  
    .auth-default-log-wrp{
      padding: 0 20px;
      width: 100%;
    }
    .alredy-signin{
      // padding-top: 10px;
      // text-align: center;
      padding-bottom: 15px;
      p {
        font-weight: 500;
        font-size: 1rem;
        line-height: 18px;
        color: #000000;
      }
    }
  
  }
}

.forgot-already-signin{
  text-align: center !important;
}


.font-weight-900{
  font-weight: 900;
}