@import '../../scss/colors.scss';
// @import '/src/fonts/Dubai-Light.ttf';

// Profile Information
.profile-wrp{
    padding: 20px 20px 0;
    .profile-user-sec-wrp{
      background: #FFFFFF;
      border-radius: 0.5rem;
      height: 6rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative;
      overflow: hidden;
      // .dsn-circle{
      //   width: 90px;
      //   height: 90px;
      //   background-color: #FFF2EB;
      //   border-radius: 90px;
      //   position: absolute;
      //   right: -20px;
      //   bottom: -15px;
      // }
    }
    .profile-height-change{
      height: 65px !important;
}
    .pro-lft-menu{
      width: 5rem;
      height: 5rem;
      background-color: #FF5F07;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.3rem;
      margin-top: 0.4rem;
      margin-bottom: 0.8rem;
    }
    .pro-right{
      padding-left: 20px;
      position: relative;
      z-index: 2;
      h3{
        padding-bottom: 3px;
        font-weight: 700;
      }
      p{
        // font-weight: 500;
        font-family: Dubai-Light;
        font-style: light;
        font-size: 18px;
        line-height: 18px;
        color: #000000;
      }
    }
    .profile-user-list-wrp{
      padding-top: 2.8rem;
      .profile-list {
        padding: 0;
        background-color: $white;
        height: 4rem;
        border-radius: 4px;
        margin-bottom: 0.5rem;
          &:last-child {
            a {
              border-bottom: 0;
            }
          }
          &:hover{
            a {
              background-color: #fbfbfb;
              transition: 0.3s ease-in background-color;
            }
          }
          a {
            display: flex;
            align-items: center;
            // padding: 10px;
            // border-bottom: 1px solid #F3F3F3;
            transition: 0.3s ease-in background-color;
            p{
              color: #000;
              padding-left: 1.7rem;
              font-size: 1.3rem;
              padding-top: 1.4rem;
              padding-bottom: 1.4rem;
            }
          }
        
      }
    }
  }

  .white-image {
    // width:30px;
    // height: 30px;
  mix-blend-mode: plus-lighter;
  filter: brightness(200%);
}

.profile-img{
  width: 2.5rem !important;
  height: 2.8rem !important;
  margin-left: 0.7rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
}

.profile-menu-header-text{
  display: flex;
  margin: 0 auto; 
  p{
    font-weight: 300 !important;
    font-size: 1.7rem !important;
    padding-right: 4rem;

  }
}

@media(max-width: 767px){  
  .profile-menu-header-text p {
    font-size: 1.5rem !important;
    color: #000000 !important;
  }
  .profile-wrp{
    padding: 0px 1rem 0;
  }
}

.profile-menu-info{
  background-image: url(../TuneUp/other_bg.png);
  // background-image: url('../ProfileMenu/images/profile-menu-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  height: 91vh;
  background-attachment: fixed;
}

@media(max-width: 767px){
  .profile-menu-info{
    background-image: url(../ProfileMenu/images/ProfileBg.svg);
    background-size: 100%;
    background-position: revert !important;
    min-height: 100vh !important;
    background-attachment: revert !important;
  }
}

.profile-terms {
  display: flex;
  flex-direction: row;
  padding-left: 4px;
  padding-right: 4px;
  justify-content: space-between;
  padding-top: 0.3rem;
  columns: #000000;
  font-weight: 300;
  line-height: 18px;
  font-size: 16px;
}

.firstName {
font-weight: 700;
font-family: "Dubai";
font-style: bold;
font-size: 1.5rem;
line-height: 18px;
color:#000000;
padding-left: 1rem;
}

.lastName{
font-weight: 700;
font-style: bold;
font-size: 1.5rem;
line-height: 18px;
color:#000000;
font-family: "Dubai";
padding-left: 5px;

}

// .profilebg{
//   width:100%;
//   height: 100vh;
// // background-image: uri('/src/Views/ProfileMenu/images/profile-menu-background.png');
// }
// .form-city{
//   width: 144% !important;
//   margin-left: -45px;
// }

// @media(max-width: 915px){
//   .form-city{
//     margin-left: -28px;
//   }
// }

// .form-zipcode{
//   width: 75% !important;
// }

// .form-state{
//  width: 79% !important;
//  margin-left:40px;
// }

// @media(max-width: 915px){
//   .form-state{
//     margin-left: 24px;
//   }
// }

.form-address{
  height: 45px !important;
  padding: 9px 8px !important;
}
.form-height{
    // height: 40px !important;
    padding: 18px 8px !important;
}

.form-lbl-profileinfo{
    font-size: 10px !important;
    // bottom: 11px !important;
}

.lbl-address{
  // bottom: -2px !important;
}

.lbl-city{
  // left: -35px;
  // bottom: 14px !important;
  font-size: 10px !important;

}
// @media(max-width: 915px){
//   .lbl-city{
//     left: -16px;
//   }
// }

.lbl-state{
  // left: 48px;
  // bottom: 14px !important;
  font-size: 10px !important;

}
// @media(max-width: 915px){
//   .lbl-state{
//     left: 35px;
//   }
// }

.lbl-zipcode{
  font-size: 10px !important;
  // bottom: 14px !important;
}

.react-custom{
  height: 40px !important;
}

.card-info {
  padding-bottom: 15px;
}

.card-num-span{
  margin-left: 38px;
}

.card-hidden{
  font-size:10px;
}

.card-expiry-span{
  margin-left: 27px;
}

.card-brand-span{
  margin-left: 60px;
  margin-bottom: 10px;
}

.div-card{
  margin-bottom: 30px;
}

.form-btn-save{
  margin-bottom: 1rem;
}

// .dropdown-model{
//   width: 49%;
// }


.profile-height-change{
  height: 65px;
}

// @media(min-width: 413px) and (max-width: 540px){
//     .form-zipcode{
//         width:90% !important;
//     }
//     .form-city{
//         width: 125% !important;
//         margin-left: -15px;
//         .lbl-city{
//             left: 5px !important;
//         }
//     }
    
// }

// @media(min-width: 541px) and (max-width: 820px){
//     .form-zipcode{
//         width:90% !important;
//     }
//     .form-city{
//         width: 125% !important;
//         margin-left: -20px;
//         .lbl-city{
//             left: -3px !important;
//         }
//     }

//     .form-state{
//         width:86%;
//     }
    
// }

// @media(min-width: 821px) and (max-width: 1024px){
//     .form-zipcode{
//         width:90% !important;
//     }
//     .form-city{
//         width: 125% !important;
//         margin-left: -20px;
//         .lbl-city{
//             left: 5px !important;
//         }
//     }

//     .form-state{
//         width:86%;
//         margin-left: 25px;
//         .lbl-state{
//             left: -5px;
//         }
//     }
    
// }