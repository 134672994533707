.landing_main {
    width: 100%;

    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    scroll-snap-type: y mandatory;
    overflow-x: hidden;

}

// .landing_main > section {
//     min-height: 100vh;
//     scroll-snap-align: start;  
//     background: url(../../images/bg.jpg) no-repeat;
//     background-position: center bottom;
//     background-size: cover;
//     padding: 1.429rem 0;
//   }

.landing_main>section.banner {
    background: url(../../images/home-banner-app.jpg) no-repeat;
    background-position: center bottom;
    background-size: 50rem;
    min-height: 87vh;
}

section.faq {
    min-height: 100vh;
    scroll-snap-align: start;
    background: url(../../images/bg.jpg) repeat;
    background-position: center bottom;
    background-size: contain;
    padding: 1.429rem 0;
}

.faq {
        height: 100% !important;
}

.faqContainer {
    display: flex;
    justify-content: center;

    .faqBody {
        width: 90%;
        max-width: 900px;

        h3 {
            font-size: 1.4rem !important;
            color: #FF5F07;
        }

        h4 {
            font-size: 1.3rem !important;
        }

        p {
            font-size: 1.3rem !important;
            text-align: justify;
            margin-bottom: 20px;
        }
    }
}

.faqList {
    margin: 10px 0px;
}
.faqItem {
    margin-inline-start: 25px;
    &::marker {
        font-size: 1.3rem;
        font-weight: 600;
    }

}

.faqHeading {
    font-size: 1.6rem !important;
    margin-bottom: 0px !important;
} 
.faqContent{
    margin-top: 5px;
    line-height: 1.2em;
    font-size: 1.6rem !important;
    ul {
        margin-top: 5px;
        margin-bottom: 10px;
        // list-style-position: inside;
        padding-inline-start: 24px;
        // margin-inline-start: 20px;
    }
}