.profile-info-sec-wrp{
    // padding-top: 20px;
    margin-left: 1rem;
    margin-right: 1rem;
}
.date_img{
    position: absolute;
    right: 0;
    width: 58%;
    margin-top: 5px;
}
@media(max-width: 915px){
    .date_img{
        width: 60%;
    }
}

// Card List Design
.crd-details{
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: 0.03);
    border-radius: 6px;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    .crd-name{
        padding-right: 5px;
        p {
            color: #000;
            font-weight: bold;
        }
    }
    .crd-info{
       padding-left: 10px;
       width: 100%;
        .crd-num{
           font-size: 18px; 
           color: #999;
           font-weight: bold;
           span {
            color: #FF5F07;
           }
        }
        .crd-expde{
            display: flex;
            flex-direction: row;
            padding-top: 5px;
            justify-content: space-between;
            p{
                font-size: 12px;
                color: #999;
                span {
                    color: #FF5F07;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
            .crd-exmonth{
                padding-right: 5px;
            }
            .crd-exyear{

            }
        }
    }
}

.paymenthdtxt{
    h3{
        text-align: left;
        padding-bottom: 15px;
    }
}

// .btn-update {
//     margin-top: 15px;
// }

//    backgroundImage: `url('${profileMenuBackground}')`, backgroundRepeat: 'no-repeat',
//           backgroundSize: '600px', backgroundPosition: 'center', height: '100%',

.profile-bg-background{
    background-image: url(../TuneUp/other_bg.png);
//   background-image: url(../ProfileMenu/images/profile-menu-background.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  background-attachment: fixed;
}
@media(max-width: 767px){
    .profile-bg-background{
        background-image: url(../ProfileMenu/images/ProfileBg.svg);
        background-size: 100%;
        background-position: revert !important;
        min-height: 100vh !important;
        background-attachment: revert !important;
    }
}
// @media (min-width: 820px) and (max-width: 912px){
//     .profile-bg-background{
//         background-size: 100% 100%;
//         height: 100vh;
//     }
// }
.profile-information-header-text{
  display: flex;
  margin: 0 auto; 
  p{
    font-weight: 300 !important;
    font-size: 1.5rem !important;
    padding-right: 4rem;

  }
}
// @media(max-width: 767px){
//     .profile-info-sec-wrp{
//         margin-top: 1rem !important;
//     }
//     .profile-information-header-text{
//         p{
//             color: #000000;
//             font-size: 1.5rem !important;
//         }
//     }
// }

.card-info-validation-error{
    font-size: 15px !important;
}

.credit-card{
    height: 2.5rem !important;
    width: 2.5rem !important;
    cursor: pointer;
}