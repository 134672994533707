@import '../../scss/colors.scss';

// Booking Time Screen Popup
// .booking-wrp{
//     padding-left: 20px;
//     padding-right: 20px;
//   }
//   .rd-popup-wrp{
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(255, 255, 255, 0.93);
//     display: flex;
//     flex: 1;
//     align-items: center;
//     justify-content: center;
//     z-index: 99;
//     .rd-popup-close{
//       position: absolute;
//       top: 10%;
//       right: 5%;
//       .reg-close {
//         background: #FFF2EB;
//         box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         width: 36px;
//         height: 36px;
//         border-radius: 36px;
//         border: none;
//         cursor: pointer;
//       }
  
//     }
//     .rd-popup-content{
//       padding: 20px;
//       text-align: center;
//       h2{
//         margin-bottom: 25px;
//         font-weight: 700;
//         font-size: 20px;
//         line-height: 22px;
//         color: $dark
//       }
//       p {
//         font-weight: 400;
//         font-size: 16px;
//         line-height: 26px;
//         max-width: 65%;
//         // color: $dark;
//         margin: 0 auto;
//       }
//     }
//   }
.confirm-order-dollar{
  position: relative;
  display: flex;
  justify-content: center;
}
.order-cost{
  position: absolute;
  top: 33px;
}
.confirm-order-cost{
  font-family: "Dubai";
  font-size: 24px;
  color: #FF5F07;
  text-align: center;
  font-weight: 700;

}
.order-confirm-name{
  position: absolute;
  top: 69px;
  border: 1px solid #FF5F07;
  background-color: #FF5F07;
  border-radius: 5px;
  color: white;
  // font-family: Dubai;
  font-size: 15px;
  padding-left: 8px;
  padding-right: 8px;
  width: 11rem;
}
.order-confirm-details{
   text-align: center;
    /* border: 1px solid #FFFFFF; */
    // background-color: #FFFFFF;
    margin-top: -74px;
    border-radius: 10px;
    margin-bottom: 50px;
    border: 1px solid #888888;
}
.order_id{
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 1.09rem;
  color: #000;
}
.popup-description{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}
.order-date-details{
  display: flex;
}
.order-duration-details{
  display: flex;
}
.order-clock-details{
  display: flex;
}
.time-slot-popup{
 
  margin: 0 auto;
  // background-color: rgba(255, 255, 255, 0.95);
  border: 0.5px solid #000;
  background-color: rgba(255,255,255,0.45);
  max-width: 550px;
  padding: 30px 15px 0;
  width: 100%;
  border-radius: 30px;
  margin-top: 6%;
  // margin-bottom: 30%;
  // inset: 145px 0px 145px;
  position: relative;
}
@media(max-width: 767px){
  .time-slot-popup{
    margin-top: 0px;
  }
}
// .css-1xc3v61-indicatorContainer {
//   padding: 5px !important;
// }
.popup_dropdown .dropdown__control {
  background-color: rgba(255, 255, 255, 0.45) !important;
}
.react-dropdown .popup_dropdown .dropdown__indicator-separator {
  background: rgba(255, 255, 255, 0.45) !important;
}
.fa-caret-down{
  margin-top: -3px !important;
}
.model-select{
  width: 48.5%;
}
.service-header-text{
  display: flex;
  margin: 0 auto;
}
.service-title{
    font-weight: 300 !important;
    font-size: 1.5rem !important;
    padding-right: 3rem;
}
@media(min-width: 280px) and (max-width: 768px){
  .time-slot-popup{
    // width: 100%;
   height: 500px;
  }
}

.order-booking-time-popup{
  display: flex;
  // height: 100%;
  align-items: center;
  justify-content: center;
}
.popup_dropdown{
  width: 50%;
  margin: 0 auto;
  margin-top: 5rem;
  background-color: transparent !important;
}

@media(max-width: 767px){
  .popup_dropdown{
    width: 65% !important;
  }
}

  .order-slot-cnt{
    width: 100%;
    .form-select{
      font-size: 22px;
      display: block;
      margin-bottom: 10px;
      font-weight: bold;
      color: $black;
      text-align: center;
      padding-top: 10px;
    }
    .dropdown__placeholder {
      // color: #000;
      font-weight: 300;
      font-size: 1.15rem;
    }
    .dropdown__single-value{
      font-weight: 500;
    }
  }

  .example-custom-input {
  background: url('./DateSlot.svg');
  background-repeat: no-repeat;
  background-position: right;
  width: 100%;
  height: 48px;
  padding: 0 8px;
  background-color: #FFFFFF;
  font-size: 1rem;
  font-family: 'Dubai';
  font-style: normal;
  transition: 0.3s;
  text-indent: 0;
  margin-right: 20px;
  border-radius: 0.375rem;
  border: 0.5px solid #4A4949;
  cursor: pointer;
  // border: 2px solid #ffdab9;
  }
  // .example-custom-input:hover{
  //     border: 1px solid #ff7f50 !important;

  // }

  .example-custom-input:focus{
      border: 1px solid #ff7f50 !important;
      outline: none;
  } 

  // input:focus {
  //    /* Remove the default focus outline */
  //   border-color: initial !important; /* Set the border color to its initial value */
  // }
  