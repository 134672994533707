@import url(https://themes.googleusercontent.com/fonts/css?kit=wAPX1HepqA24RkYW1AuHYA);

.lst-kix_list_14-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_14-3 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_15-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-8 {
    list-style-type: none;
  }
  .lst-kix_list_dot-14 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_14-4 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_15-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-4 {
    list-style-type: none;
  }
  .lst-kix_list_14-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_14-7 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_15-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-0 {
    list-style-type: none;
  }
  .lst-kix_list_14-6 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_15-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_9-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-8.start {
    counter-reset: lst-ctn-kix_list_11-8 0;
  }
  ul.lst-kix_list_dot-9 {
    list-style-type: none;
  }
  .lst-kix_list_14-2 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_17-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_17-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_17-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_17-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_17-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_17-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_17-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_17-3 {
    list-style-type: none;
  }
  .lst-kix_list_14-8 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_17-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-5.start {
    counter-reset: lst-ctn-kix_list_15-5 0;
  }
  .lst-kix_list_dot-5 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_24-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_16-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-6 {
    list-style-type: none;
  }
  .lst-kix_list_24-8 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_16-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-3 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_16-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-2 {
    list-style-type: none;
  }
  .lst-kix_list_5-2 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_16-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-4 {
    list-style-type: none;
  }
  .lst-kix_list_5-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_24-2 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_1 {
    list-style-type: none;
  }
  .lst-kix_list_5-7 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_5-8 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }
  .lst-kix_list_24-3 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }
  .lst-kix_list_24-4 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }
  .lst-kix_list_24-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_5-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_5-5 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  .lst-kix_list_24-6 > li:before {
    content: "\0025cf   ";
  }
  ol.lst-kix_list_16-4.start {
    counter-reset: lst-ctn-kix_list_16-4 0;
  }
  .lst-kix_list_23-6 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_6-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_6-3 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_23-3 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_23-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_dot-6 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_6-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_23-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_dot-23 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_23-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_23-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_6-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_24-1 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_15-0.start {
    counter-reset: lst-ctn-kix_list_15-0 0;
  }
  .lst-kix_list_dot-24 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_6-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_6-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_6-7 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_23-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_23-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_6-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_7-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_7-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_15-5 > li {
    counter-increment: lst-ctn-kix_list_15-5;
  }
  .lst-kix_list_22-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_22-6 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_7-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_22-0 > li:before {
    content: "o  ";
  }
  .lst-kix_list_22-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_13-7 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_7-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_15-6 > li {
    counter-increment: lst-ctn-kix_list_15-6;
  }
  .lst-kix_list_22-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_15-5 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) "."
      counter(lst-ctn-kix_list_15-1, decimal) "."
      counter(lst-ctn-kix_list_15-2, decimal) "."
      counter(lst-ctn-kix_list_15-3, decimal) "."
      counter(lst-ctn-kix_list_15-4, decimal) "."
      counter(lst-ctn-kix_list_15-5, decimal) ". ";
  }
  .lst-kix_list_4-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_15-7 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) "."
      counter(lst-ctn-kix_list_15-1, decimal) "."
      counter(lst-ctn-kix_list_15-2, decimal) "."
      counter(lst-ctn-kix_list_15-3, decimal) "."
      counter(lst-ctn-kix_list_15-4, decimal) "."
      counter(lst-ctn-kix_list_15-5, decimal) "."
      counter(lst-ctn-kix_list_15-6, decimal) "."
      counter(lst-ctn-kix_list_15-7, decimal) ". ";
  }
  ul.lst-kix_list_19-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-6 {
    list-style-type: none;
  }
  .lst-kix_list_4-3 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_4-5 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_19-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-3 {
    list-style-type: none;
  }
  .lst-kix_list_25-5 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_19-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_19-0 {
    list-style-type: none;
  }
  .lst-kix_list_15-1 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) "."
      counter(lst-ctn-kix_list_15-1, decimal) ". ";
  }
  .lst-kix_list_25-7 > li:before {
    content: "o  ";
  }
  .lst-kix_list_15-3 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) "."
      counter(lst-ctn-kix_list_15-1, decimal) "."
      counter(lst-ctn-kix_list_15-2, decimal) "."
      counter(lst-ctn-kix_list_15-3, decimal) ". ";
  }
  ul.lst-kix_list_19-8 {
    list-style-type: none;
  }
  .lst-kix_list_16-2 > li {
    counter-increment: lst-ctn-kix_list_16-2;
  }
  ol.lst-kix_list_16-7.start {
    counter-reset: lst-ctn-kix_list_16-7 0;
  }
  .lst-kix_list_11-2 > li {
    counter-increment: lst-ctn-kix_list_11-2;
  }
  ol.lst-kix_list_15-2.start {
    counter-reset: lst-ctn-kix_list_15-2 0;
  }
  .lst-kix_list_12-3 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_12-1 > li:before {
    content: "" counter(lst-ctn-kix_list_12-1, decimal) ". ";
  }
  .lst-kix_list_16-3 > li {
    counter-increment: lst-ctn-kix_list_16-3;
  }
  ol.lst-kix_list_15-3.start {
    counter-reset: lst-ctn-kix_list_15-3 0;
  }
  .lst-kix_list_13-3 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_dot-0{
    list-style-type: none;
  }
  .lst-kix_list_13-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_12-5 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_18-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-5 {
    list-style-type: none;
  }
  .lst-kix_list_12-7 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_18-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_18-1 {
    list-style-type: none;
  }
  .lst-kix_list_13-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_dot-24 {
    list-style-type: none;
  }
  ul.lst-kix_list_24-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_24-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_24-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_24-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_24-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-3 {
    list-style-type: none;
  }
  .lst-kix_list_dot-3 > li:before {
    content: "\0025cf   ";
  }
  ol.lst-kix_list_11-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_dot-11 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_11-1 {
    list-style-type: none;
  }
  .lst-kix_list_21-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_1 > li {
    counter-increment: lst-ctn-kix_list_16-0;
  }
  ul.lst-kix_list_24-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_dot-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_24-7 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_24-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_3-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_21-0 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_21-1 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_15-8.start {
    counter-reset: lst-ctn-kix_list_15-8 0;
  }
  .lst-kix_list_8-3 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_13-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-1 {
    list-style-type: none;
  }
  .lst-kix_list_3-7 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_dot-13 {
    list-style-type: none;
  }
  .lst-kix_list_8-4 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_13-8 {
    list-style-type: none;
  }
  .lst-kix_list_11-1 > li:before {
    content: "" counter(lst-ctn-kix_list_11-1, decimal) ". ";
  }
  ul.lst-kix_list_13-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_13-6 {
    list-style-type: none;
  }
  .lst-kix_list_21-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_21-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_dot-11 > li:before {
    content: "" counter(lst-ctn-kix_list_11-0, decimal) ". ";
  }
  .lst-kix_list_8-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_16-8 > li:before {
    content: "" counter(lst-ctn-kix_list_16-8, lower-roman) ". ";
  }
  ul.lst-kix_list_dot-23 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-1 {
    list-style-type: none;
  }
  .lst-kix_list_16-7 > li:before {
    content: "" counter(lst-ctn-kix_list_16-7, lower-latin) ". ";
  }
  ul.lst-kix_list_23-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-6 {
    list-style-type: none;
  }
  .lst-kix_list_4-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_4-7 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_17-0 > li:before {
    content: "\0025cf   ";
  }
  ol.lst-kix_list_12-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-8 {
    list-style-type: none;
  }
  .lst-kix_list_1 > li:before {
    content: "" counter(lst-ctn-kix_list_16-0, decimal) ". ";
  }
  ul.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-7 {
    list-style-type: none;
  }
  .lst-kix_list_25-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_dot-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-7 {
    list-style-type: none;
  }
  .lst-kix_list_25-0 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_16-4 > li:before {
    content: "" counter(lst-ctn-kix_list_16-4, lower-latin) ". ";
  }
  ul.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_23-8 {
    list-style-type: none;
  }
  .lst-kix_list_16-3 > li:before {
    content: "" counter(lst-ctn-kix_list_16-3, decimal) ". ";
  }
  ul.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_4-3 {
    list-style-type: none;
  }
  .lst-kix_list_11-3 > li {
    counter-increment: lst-ctn-kix_list_11-3;
  }
  ul.lst-kix_list_12-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-5 {
    list-style-type: none;
  }
  .lst-kix_list_17-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_12-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-2 {
    list-style-type: none;
  }
  .lst-kix_list_16-7 > li {
    counter-increment: lst-ctn-kix_list_16-7;
  }
  .lst-kix_list_17-8 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_dot-12 {
    list-style-type: none;
  }
  .lst-kix_list_17-3 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_17-4 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_12-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_12-7 {
    list-style-type: none;
  }
  .lst-kix_list_dot-7 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_16-5 > li {
    counter-increment: lst-ctn-kix_list_16-5;
  }
  .lst-kix_list_22-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_2-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_2-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_22-1 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_15-7.start {
    counter-reset: lst-ctn-kix_list_15-7 0;
  }
  .lst-kix_list_7-3 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  .lst-kix_list_dot-10 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  .lst-kix_list_13-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_18-3 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_18-7 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_dot-7 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_15-4 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) "."
      counter(lst-ctn-kix_list_15-1, decimal) "."
      counter(lst-ctn-kix_list_15-2, decimal) "."
      counter(lst-ctn-kix_list_15-3, decimal) "."
      counter(lst-ctn-kix_list_15-4, decimal) ". ";
  }
  .lst-kix_list_10-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_10-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_dot-4 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_15-0 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) ". ";
  }
  .lst-kix_list_15-8 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) "."
      counter(lst-ctn-kix_list_15-1, decimal) "."
      counter(lst-ctn-kix_list_15-2, decimal) "."
      counter(lst-ctn-kix_list_15-3, decimal) "."
      counter(lst-ctn-kix_list_15-4, decimal) "."
      counter(lst-ctn-kix_list_15-5, decimal) "."
      counter(lst-ctn-kix_list_15-6, decimal) "."
      counter(lst-ctn-kix_list_15-7, decimal) "."
      counter(lst-ctn-kix_list_15-8, decimal) ". ";
  }
  .lst-kix_list_15-7 > li {
    counter-increment: lst-ctn-kix_list_15-7;
  }
  .lst-kix_list_4-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_25-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_9-3 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_25-8 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_25-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_25-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_25-2 {
    list-style-type: none;
  }
  .lst-kix_list_9-7 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_25-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_25-4 {
    list-style-type: none;
  }
  .lst-kix_list_11-4 > li:before {
    content: "" counter(lst-ctn-kix_list_11-4, decimal) ". ";
  }
  .lst-kix_list_15-2 > li {
    counter-increment: lst-ctn-kix_list_15-2;
  }
  .lst-kix_list_12-4 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-4 {
    list-style-type: none;
  }
  .lst-kix_list_20-5 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_25-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-0 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_25-6 {
    list-style-type: none;
  }
  .lst-kix_list_20-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_25-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_25-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_6-2 {
    list-style-type: none;
  }
  .lst-kix_list_11-8 > li:before {
    content: "" counter(lst-ctn-kix_list_11-8, decimal) ". ";
  }
  ul.lst-kix_list_6-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_dot-6 {
    list-style-type: none;
  }
  .lst-kix_list_dot-12 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_6-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_dot-13 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_14-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-2 {
    list-style-type: none;
  }
  .lst-kix_list_13-4 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_14-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_dot-14 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_14-7 {
    list-style-type: none;
  }
  .lst-kix_list_dot-1 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_14-6 {
    list-style-type: none;
  }
  .lst-kix_list_11-5 > li {
    counter-increment: lst-ctn-kix_list_11-5;
  }
  ul.lst-kix_list_14-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_12-8 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_20-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-3 {
    list-style-type: none;
  }
  .lst-kix_list_19-0 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_19-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_20-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-8 {
    list-style-type: none;
  }
  .lst-kix_list_19-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_19-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_19-3 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_20-0 {
    list-style-type: none;
  }
  ul.lst-kix_list_20-1 {
    list-style-type: none;
  }
  .lst-kix_list_15-0 > li {
    counter-increment: lst-ctn-kix_list_15-0;
  }
  .lst-kix_list_dot-11 > li {
    counter-increment: lst-ctn-kix_list_11-0;
  }
  ul.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_1-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_16-3.start {
    counter-reset: lst-ctn-kix_list_16-3 0;
  }
  .lst-kix_list_19-8 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_11-2.start {
    counter-reset: lst-ctn-kix_list_11-2 0;
  }
  .lst-kix_list_19-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_19-6 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_19-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_15-6.start {
    counter-reset: lst-ctn-kix_list_15-6 0;
  }
  .lst-kix_list_12-1 > li {
    counter-increment: lst-ctn-kix_list_12-1;
  }
  .lst-kix_list_dot-0> li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_18-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_18-2 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_11-7.start {
    counter-reset: lst-ctn-kix_list_11-7 0;
  }
  .lst-kix_list_16-1 > li {
    counter-increment: lst-ctn-kix_list_16-1;
  }
  ul.lst-kix_list_22-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-7 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_22-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-5 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_22-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-7 {
    list-style-type: none;
  }
  .lst-kix_list_18-6 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_10-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_18-4 > li:before {
    content: "o  ";
  }
  .lst-kix_list_18-8 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_22-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_15-1.start {
    counter-reset: lst-ctn-kix_list_15-1 0;
  }
  ol.lst-kix_list_15-4.start {
    counter-reset: lst-ctn-kix_list_15-4 0;
  }
  ul.lst-kix_list_dot-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_3-4 {
    list-style-type: none;
  }
  .lst-kix_list_10-7 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_10-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_10-3 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_15-4 > li {
    counter-increment: lst-ctn-kix_list_15-4;
  }
  .lst-kix_list_11-7 > li {
    counter-increment: lst-ctn-kix_list_11-7;
  }
  .lst-kix_list_9-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_20-8 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_dot-9 > li:before {
    content: "\0025cf   ";
  }
  ul.lst-kix_list_21-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-3 {
    list-style-type: none;
  }
  .lst-kix_list_16-8 > li {
    counter-increment: lst-ctn-kix_list_16-8;
  }
  ul.lst-kix_list_21-4 {
    list-style-type: none;
  }
  .lst-kix_list_20-0 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_21-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_21-8 {
    list-style-type: none;
  }
  .lst-kix_list_9-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_9-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_11-3 > li:before {
    content: "" counter(lst-ctn-kix_list_11-3, decimal) ". ";
  }
  ul.lst-kix_list_21-0 {
    list-style-type: none;
  }
  .lst-kix_list_20-6 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_2-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_12-1.start {
    counter-reset: lst-ctn-kix_list_12-1 0;
  }
  .lst-kix_list_20-4 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_11-5 > li:before {
    content: "" counter(lst-ctn-kix_list_11-5, decimal) ". ";
  }
  ul.lst-kix_list_2-2 {
    list-style-type: none;
  }
  .lst-kix_list_20-2 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_dot-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-1 {
    list-style-type: none;
  }
  .lst-kix_list_9-8 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_2-6 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li:before {
    content: "o  ";
  }
  ul.lst-kix_list_2-7 {
    list-style-type: none;
  }
  .lst-kix_list_11-7 > li:before {
    content: "" counter(lst-ctn-kix_list_11-7, decimal) ". ";
  }
  ul.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_2-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_dot-10 {
    list-style-type: none;
  }
  .lst-kix_list_1-3 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_16-8.start {
    counter-reset: lst-ctn-kix_list_16-8 0;
  }
  ul.lst-kix_list_10-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-7 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_10-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-3 {
    list-style-type: none;
  }
  .lst-kix_list_1-5 > li:before {
    content: "\0025aa   ";
  }
  ul.lst-kix_list_10-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_10-1 {
    list-style-type: none;
  }
  .lst-kix_list_2-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_2-3 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_11-8 > li {
    counter-increment: lst-ctn-kix_list_11-8;
  }
  .lst-kix_list_3-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_3-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_8-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_8-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_3-5 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_11-5.start {
    counter-reset: lst-ctn-kix_list_11-5 0;
  }
  .lst-kix_list_21-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_8-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_11-1 > li {
    counter-increment: lst-ctn-kix_list_11-1;
  }
  .lst-kix_list_8-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_15-1 > li {
    counter-increment: lst-ctn-kix_list_15-1;
  }
  .lst-kix_list_3-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_21-6 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_16-6.start {
    counter-reset: lst-ctn-kix_list_16-6 0;
  }
  .lst-kix_list_21-7 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_11-2 > li:before {
    content: "" counter(lst-ctn-kix_list_11-2, decimal) ". ";
  }
  ol.lst-kix_list_1.start {
    counter-reset: lst-ctn-kix_list_16-0 0;
  }
  .lst-kix_list_21-3 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_11-6.start {
    counter-reset: lst-ctn-kix_list_11-6 0;
  }
  .lst-kix_list_16-6 > li:before {
    content: "" counter(lst-ctn-kix_list_16-6, decimal) ". ";
  }
  .lst-kix_list_25-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_17-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_25-3 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_16-1 > li:before {
    content: "" counter(lst-ctn-kix_list_16-1, lower-latin) ". ";
  }
  .lst-kix_list_16-2 > li:before {
    content: "" counter(lst-ctn-kix_list_16-2, lower-roman) ". ";
  }
  .lst-kix_list_16-5 > li:before {
    content: "" counter(lst-ctn-kix_list_16-5, lower-roman) ". ";
  }
  .lst-kix_list_15-3 > li {
    counter-increment: lst-ctn-kix_list_15-3;
  }
  ol.lst-kix_list_dot-11.start {
    counter-reset: lst-ctn-kix_list_11-0 0;
  }
  .lst-kix_list_16-4 > li {
    counter-increment: lst-ctn-kix_list_16-4;
  }
  .lst-kix_list_17-2 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_16-5.start {
    counter-reset: lst-ctn-kix_list_16-5 0;
  }
  .lst-kix_list_17-6 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_17-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_2-6 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_16-2.start {
    counter-reset: lst-ctn-kix_list_16-2 0;
  }
  .lst-kix_list_22-3 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_7-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_7-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_22-7 > li:before {
    content: "o  ";
  }
  ol.lst-kix_list_11-1.start {
    counter-reset: lst-ctn-kix_list_11-1 0;
  }
  .lst-kix_list_18-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_13-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_16-6 > li {
    counter-increment: lst-ctn-kix_list_16-6;
  }
  .lst-kix_list_11-6 > li {
    counter-increment: lst-ctn-kix_list_11-6;
  }
  .lst-kix_list_15-6 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) "."
      counter(lst-ctn-kix_list_15-1, decimal) "."
      counter(lst-ctn-kix_list_15-2, decimal) "."
      counter(lst-ctn-kix_list_15-3, decimal) "."
      counter(lst-ctn-kix_list_15-4, decimal) "."
      counter(lst-ctn-kix_list_15-5, decimal) "."
      counter(lst-ctn-kix_list_15-6, decimal) ". ";
  }
  .lst-kix_list_11-4 > li {
    counter-increment: lst-ctn-kix_list_11-4;
  }
  .lst-kix_list_10-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_20-7 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_4-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_4-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_15-2 > li:before {
    content: "" counter(lst-ctn-kix_list_15-0, decimal) "."
      counter(lst-ctn-kix_list_15-1, decimal) "."
      counter(lst-ctn-kix_list_15-2, decimal) ". ";
  }
  .lst-kix_list_25-6 > li:before {
    content: "\0025cf   ";
  }
  .lst-kix_list_10-6 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_9-1 > li:before {
    content: "o  ";
  }
  .lst-kix_list_15-8 > li {
    counter-increment: lst-ctn-kix_list_15-8;
  }
  .lst-kix_list_9-5 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_12-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_11-6 > li:before {
    content: "" counter(lst-ctn-kix_list_11-6, decimal) ". ";
  }
  .lst-kix_list_20-3 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_11-3.start {
    counter-reset: lst-ctn-kix_list_11-3 0;
  }
  .lst-kix_list_1-2 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_16-1.start {
    counter-reset: lst-ctn-kix_list_16-1 0;
  }
  .lst-kix_list_1-6 > li:before {
    content: "\0025aa   ";
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_12-6 > li:before {
    content: "\0025aa   ";
  }
  ol.lst-kix_list_11-4.start {
    counter-reset: lst-ctn-kix_list_11-4 0;
  }
  .lst-kix_list_2-2 > li:before {
    content: "\0025aa   ";
  }
  .lst-kix_list_13-2 > li:before {
    content: "\0025aa   ";
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c71 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: middle;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 41pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c53 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 63.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c48 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 44.1pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c43 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 37.3pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c74 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: middle;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 37.3pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c77 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: middle;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 44.1pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c63 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 61.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c6 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #deebf6;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 63.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c80 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 63.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c42 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 41pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c65 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 324.9pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c49 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: middle;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 47pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c44 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 47.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c40 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 62.4pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c28 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: middle;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 46.4pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c50 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: middle;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 61.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c45 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 46.4pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c13 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 37.3pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c19 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #deebf6;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 47.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c68 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 47pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c54 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #deebf6;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 41pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c12 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #deebf6;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 37.3pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c76 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 47pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c60 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 61.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c73 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 0pt;
    border-left-color: #000000;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 0pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 46.4pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c24 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #deebf6;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 47pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c32 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #deebf6;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 46.4pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c61 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: middle;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 47.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c55 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 0pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #000000;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #5b9bd5;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 125.9pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c72 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 41pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c33 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #deebf6;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 61.5pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c31 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #bdd7ee;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 44.1pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c69 {
    border-right-style: solid;
    padding: 0pt 5.8pt 0pt 5.8pt;
    border-bottom-color: #ffffff;
    border-top-width: 1pt;
    border-right-width: 1pt;
    border-left-color: #ffffff;
    vertical-align: top;
    border-right-color: #ffffff;
    border-left-width: 1pt;
    border-top-style: solid;
    background-color: #deebf6;
    border-left-style: solid;
    border-bottom-width: 1pt;
    width: 44.1pt;
    border-top-color: #ffffff;
    border-bottom-style: solid;
  }
  .c56 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    font-weight: 400;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 11pt;
    font-family: "Dubai Light";
    font-style: normal;
  }
  .c2 {
    margin-left: 36pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 18pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px){
    .c2{
      margin-left: 25pt;
    }
  }
  .c38 {
    width: max-content;
    padding-top: 0pt;
    border-bottom-color: #000000;
    border-bottom-width: 3pt;
    padding-bottom: 30pt;
    line-height: 1;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c26 {
    margin-left: 60pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c17 {
    margin-left: 72pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px){
    .c17{
      margin-left: 40pt;
    }
  }
  .c11 {
    margin-left: 54pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px) {
    .c11{
      margin-left: 40pt;
    }
  }
  .c23 {
    margin-left: 36pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px) {
    .c23{
      margin-left: 40pt;
    }
  }
  .c18 {
    margin-left: 72pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px){
    .c18{
      margin-left: 40pt;
    }
  }
  .c1 {
    margin-left: 72pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px){
    .c1{
      margin-left: 40pt;
    }
  }
  .c0 {
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt;
  }
  .c51 {
    margin-left: 60pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c3 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Dubai Light";
    font-style: normal;
  }
  .c5 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-family: "Dubai Light";
    font-style: normal;
  }
  .c15 {
    margin-left: 36pt !important;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px){
    .c15{
      margin-left: 15pt !important;
    }
  }
  .c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Dubai Light";
    font-style: normal;
  }
  .c34 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal;
  }
  .c46 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Dubai Light";
    font-style: normal;
  }
  .c9 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Dubai Light";
    font-style: normal;
  }
  .c66 {
    margin-left: 108pt;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px) {
    .c66{
      margin-left: 69pt;
    }
  }
  .c14 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 8pt;
    font-family: "Dubai Light";
    font-style: normal;
  }
  .c58 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Dubai";
    font-style: normal;
  }
  .c30 {
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c37 {
    padding-top: 0pt;
    text-indent: 36pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  @media(max-width: 768px) {
    .c37{
      text-indent: 0pt;
      padding-left: 15pt;
    }
  }
  .c20 {
    padding-top: 0pt;
    padding-bottom: 18pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c52 {
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 16pt;
    font-style: normal;
  }
  .c59 {
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c70 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c10 {
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c7 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.75;
    orphans: 2;
    widows: 2;
    text-align: center;
  }
  .c16 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 0.75;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c39 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    text-align: left;
  }
  .c57 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-style: normal;
  }
  .c75 {
    border-spacing: 0;
    border-collapse: collapse;
    margin-right: auto;
  }
  .c67 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10pt;
    font-style: normal;
  }
  .c36 {
    font-size: 11pt;
    font-family: "Dubai Light";
    font-weight: 700;
  }
  .c64 {
    font-size: 11pt;
    font-family: "Dubai Light";
    font-weight: 400;
  }
  .c8 {
    font-family: "Dubai Light";
    color: #000000;
    font-weight: 700;
  }
  .c35 {
    font-size: 10pt;
    font-family: "Dubai Light";
    font-weight: 400;
  }
  .c79 {
    background-color: #ffffff;
    max-width: 587.3pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  @media(max-width: 768px) {
    .c79{
      padding: 72pt 72pt 72pt 30pt;
    }
  }
  .c25 {
    padding: 0;
    margin: 0;
  }
  .c78 {
    margin-left: 60pt;
  }
  .c62 {
    height: 0pt;
  }
  .c41 {
    padding-left: 0pt;
  }
  .c47 {
    margin-left: 72pt;
  }
  @media(max-width: 768px){
    .c47 {
      margin-left: 40pt;
    }
  }
  .c27 {
    margin-left: 36pt !important;
  }
  @media(max-width: 768px) {
    .c27{
      margin-left: 9pt !important;
    }
  }
  .c22 {
    height: 12pt;
  }
  .c29 {
    margin-left: 54pt !important;
  }
  @media(max-width: 768px){
    .c29{
      margin-left: 30pt !important;
    }
  }
  .c21 {
    height: 87.3pt;
  }
  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Times New Roman";
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .li-text {
    color: #000000;
    font-size: 12pt;
    font-family: "Times New Roman";
  }
  .p-content {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Times New Roman";
  }
  // h1 {
  //   padding-top: 12pt;
  //   color: #2f5496;
  //   font-size: 16pt;
  //   padding-bottom: 0pt;
  //   font-family: "Calibri";
  //   line-height: 1;
  //   page-break-after: avoid;
  //   orphans: 2;
  //   widows: 2;
  //   text-align: left;
  // }
  // h2 {
  //   padding-top: 18pt;
  //   color: #000000;
  //   font-weight: 700;
  //   font-size: 18pt;
  //   padding-bottom: 4pt;
  //   font-family: "Times New Roman";
  //   line-height: 1;
  //   page-break-after: avoid;
  //   orphans: 2;
  //   widows: 2;
  //   text-align: left;
  // }
  // h3 {
  //   padding-top: 14pt;
  //   color: #000000;
  //   font-weight: 700;
  //   font-size: 14pt;
  //   padding-bottom: 4pt;
  //   font-family: "Times New Roman";
  //   line-height: 1;
  //   page-break-after: avoid;
  //   orphans: 2;
  //   widows: 2;
  //   text-align: left;
  // }
  // h4 {
  //   padding-top: 12pt;
  //   color: #000000;
  //   font-weight: 700;
  //   font-size: 12pt;
  //   padding-bottom: 2pt;
  //   font-family: "Times New Roman";
  //   line-height: 1;
  //   page-break-after: avoid;
  //   orphans: 2;
  //   widows: 2;
  //   text-align: left;
  // }
  // h5 {
  //   padding-top: 11pt;
  //   color: #000000;
  //   font-weight: 700;
  //   font-size: 11pt;
  //   padding-bottom: 2pt;
  //   font-family: "Times New Roman";
  //   line-height: 1;
  //   page-break-after: avoid;
  //   orphans: 2;
  //   widows: 2;
  //   text-align: left;
  // }
  // h6 {
  //   padding-top: 10pt;
  //   color: #000000;
  //   font-weight: 700;
  //   font-size: 10pt;
  //   padding-bottom: 2pt;
  //   font-family: "Times New Roman";
  //   line-height: 1;
  //   page-break-after: avoid;
  //   orphans: 2;
  //   widows: 2;
  //   text-align: left;
  // }

  /* Add these CSS rules at the end of your existing CSS */

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .table-container {
    overflow-x: auto;
  }
  .c75 {
   min-width: max-content;
    font-size: 12px;
  }

  .c55, .c65, .c61 {
    width: auto;
    display: table-cell;
  }
}

//Headers

.header-terms-wrp {
  max-width: 395px;

  header {
      height: 70px;
      // background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: left;
      padding-left: 72pt;
      // border-bottom: 1px solid #f1f1f1;
      // box-shadow: 0px 0px 2px rgb(0 0 0 / 15%);
      // border-radius: 0px 0px 50px 0px;

      &.hd-centered {
          justify-content: center;
          text-align: center;
          padding-left: 0;
      }

      img {
          max-width: 260px;
          // margin-top: 6px;
      }

      .header-terms-backic {
          background-color: #FFF2EB;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
          width: 36px;
          height: 36px;
          border-radius: 36px;
          display: flex;
          align-items: center;
          justify-content: center;

          .header-terms-backic-icon {
              display: flex;
              background: transparent;
              border: none;
              cursor: pointer;
          }
      }

      .header-mnutxt {
          // padding-left: 20px;
          font-weight: 500;
          font-size: 1.5rem;
          line-height: 18px;
          text-align: center;
          color: #000000;
      }
  }
}
@media(max-width: 768px) {
  header{
      padding-left: 20px !important;
  }
}
