@import '../../scss/colors.scss';

// Order Hirtory Start
.order-history-wrp{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    .order-history-list{
      .order-history-item{
        .order-history-item-link{
          display: flex;
          background: white;
          border-radius: 0.375rem;
          flex-direction: row;
          height: 4.5rem;
          align-items: center;
          margin-bottom: 1rem;
          // padding: 0 15px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
          .order-hisroy-img{
            width: 3.4rem;
            height: 3rem;
            border-radius: 0.375rem;
            padding: 0px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            margin-left: 0.6rem;
            background-color: #d9d9d9d9;
  
            img{
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          
          .order-hisroy-item-cnt{
            padding-left: 15px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .order-history-description{
            // margin-top: 6px;
            display: flex;
            flex-direction: column;
            color: #AAAAAA;
            width:100%;
            margin-top: 6px;
          }
            h4 {
              font-weight: 700;
              font-size: 1.09rem;
              line-height: 18px;
              color: $dark;
            }

            .order-hisroy-mech{
              font-weight: 300;
              font-size: 14px;
              color: $dark;
            }
            .order-hisroy-item-stat{
              display: flex;
              flex-direction: column;
              width: 100%;
              justify-content: end;
              align-items: end;

              .order-margin-top{
                margin-right: 0.4rem;
              }
              &-txt {
                // background: #FFB400;
                padding: 5px 0;
                border-radius: 4px;
                font-weight: 500;
                font-size: 1rem;
                line-height: 11px;
                color: #FFFFFF;
                width: 90px;
                // text-transform: capitalize;
                text-align: center;
              }
              .stat-confirmed{
                background: #24AD65;
              }
              .stat-canceled{
                background: #E94E4E;
              }
              .stat-completed{
                background: #0481df;
              }
              .order-hisroy-item-date{
                // padding-top: 15px;
                font-weight: 300;
                font-size: 1rem;
                padding-right: 0.8rem;
                padding-top: 0.8rem;
                line-height: 16px;
                color: #5F5F5F;
              }

            }

         }
  
        }
        
      }
    }
  }

  @media(max-width: 767px){
    .order-history-item-link{
      height: 4rem !important;
    }
  }
  // .order-history-main{
  //   margin-bottom: 600px;
  // }

  .footercontainer{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: fit-content;
  }

  .btn{
    border-radius: 0.63rem !important;
    // margin-bottom: 30px;

  }
  // @media(min-width: 768px) and (max-width: 820px){
  //   .btn{
  //     margin-bottom: 30px;
  //   }
  // }

  .page-loading{
    text-align: center;
  }

  .order-image-upload{
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 0.8rem;
    border-radius: 5px;
  }

  .order-image-top-text{
    border: 1px solid white;
    position: relative;
    left: 1.6rem;
    top: 1px;
    width: fit-content;
    background-color: white;
    padding: 0px 6px;
    font-size: 12px;
    font-weight: 300 !important;
  }

  .order-image-count{
    position: absolute;
    display: flex;
    font-weight: bold;
    left: 45%;
    bottom: 25%;
    color: white;
    font-size: 48px; 
   }

    .order-image-count-custom{
    position: absolute;
    display: flex;
    font-weight: bold;
    left: 45%;
    bottom: 21%;
    color: white;
    font-size: 48px; 
   }

    .order-image-count-completed{
    position: absolute;
    display: flex;
    font-weight: bold;
    left: 45%;
    bottom: 40%;
    color: white;
    font-size: 48px; 
   }

     .order-image-count-cancel{
    position: absolute;
    display: flex;
    font-weight: bold;
    left: 45%;
    bottom: 21%;
    color: white;
    font-size: 48px; 
   }

  .order-image{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }

  .order-help{
    font-weight: 700;
    font-size: 14px;

    span{
      font-weight: 300;
      color: #FF5F07;
    }
  }

  .btn-edit-order{
    background-color: #507C76;
    border: 1px solid #507C76;
    margin-left: 5px;
  }
  .btn-edit-order:hover{
    background-color: #507C76;
    border: 1px solid #507C76;
  }
  .btn-edit-order:focus{
    background-color: #507C76;
    border: 1px solid #507C76;
  }
  .btn-cancel-order{
    margin-right: 5px;
    background-color: #AAAAAA;
    border: 1px solid #AAAAAA;
  }
  .btn-cancel-order:hover{
    background-color: #AAAAAA;
    border: 1px solid #AAAAAA;
  }
  .btn-cancel-order:focus{
    background-color: #AAAAAA !important;
    border: 1px solid #AAAAAA !important;
  }

  .custom{
    // margin: 20px 20px 80px 20px !important;

  }
  .order-history-header{
    text-align: center;
    display: flex !important;
    justify-content: center;
    margin: 0 auto;
    p{
        padding-right: 4rem;
        font-size: 1.5rem !important;
        font-weight: 300 !important;
    }
  }

  .order-history-bg{
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-image: url(../TuneUp/other_bg.png);
  min-height: 100vh;
  background-attachment: fixed;
}
@media(max-width: 767px){
  .order-history-bg{
    background-image: url(../TuneUp/OrderListBg.svg);
    background-size: 100%;
    background-position: revert !important;
    min-height: 100vh !important;
    background-attachment: fixed !important;
  }
}
