@import '../../scss/colors.scss';

.tabsdata{
  display: none;
  &.react-tabs__tab-panel--selected{
    display: block;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.tuneup-bg{
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-image: url(../TuneUp/other_bg.png);
  min-height: 100vh;
  background-attachment: fixed;
}
@media(max-width: 767px){
  .tuneup-bg{
    background-image: url(../ProfileMenu/images/ProfileBg.svg);
    background-size: 100%;
    background-position: revert !important;
    min-height: 100vh !important;
  }
}

.tuneup-header-text{
  display: flex;
  margin: 0 auto;
}
.tuneup-title{
  font-weight: 300 !important;
    font-size: 1.5rem !important;
    padding-right: 4rem;
}

// Tune Up
.tune-up-main-wrp {
  // margin-top: 20px;
  // margin-left: 3%;
  // margin-right: 3%;
  .tune-up-tab {
    // margin-bottom: 9px;
    ul {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      justify-content: space-between;
      align-items: center;
      // height: 5rem;

      li {
        justify-content: center;
          display: flex;
          // border: 1px solid white;
          // background: $secondary;
          // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
          // border-radius: 35px 0px;
          background-image: url(../TuneUp/default_box.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          text-align: center;
          height: 5rem;
          color: #fff;
          align-items: center;
          width: 100%;
          justify-content: center;
          font-weight: 300;
          font-size: 1rem;
          line-height: 18px;
          cursor: pointer;
          // margin: 0 5px;
          &.react-tabs__tab--selected{
            // background: $primary;
            display: flex;
            background-image: url(../TuneUp/basic_box.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 6.4rem;
            align-items: revert !important;
            padding-top: 28px;
            margin-top: 15px;
          }
          &.tune-up-signletab{
            display: none;
          }
      }
    }
  }

  .tune-amt-wrp {
    // margin-top: 10px;

    .tune-amt-cnt {
      background-image: url(../TuneUp/CurrencyBg.svg);
        background-repeat: no-repeat;
        background-size: 184px 116px;
        background-position: center;
        height: 116px;
      // background-color: #fff;
      // display: flex;
      // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      // border-radius: 10px;
      padding: 25px 25px;
      text-align: center;
      // justify-content: center;

      .tune-name-price,
      .tune-duration {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: $black;
        margin-top: 7px;
        // padding: 0 5px;
        strong{
          font-weight: 500;
        }
        span {
          color: $primary;
          font-weight: 800;
          font-size: 1.5rem;
        }
      }
    }
    .tune-amt-desc{
      border-radius: 5px;
    border: 1px solid #FF5F07;
    width: 147px;
    // height: 22px;
    // margin: 0 auto;
    padding-top: 2px;
    background-color: #FF5F07;
    color: white;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    }

    .tune-service-title{
      display: flex;
      justify-content: space-between;
      padding-top: 0.8rem;
      padding-left: 0.7rem;
      padding-right: 0.7rem;
    }

    .tune-desc-wrp {
      background: #FFFFFF;
      // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 0.63rem;
      margin-top: -41px;
      margin-bottom: 0.7rem;
      // padding: 20px;

      h3 {
        padding-bottom: 5px;
      }

      ul {
        padding-left: 20px;

        li {
          padding-bottom: 7px;
          font-weight: 300;
          color: #000000;
          font-size: 14px;
          line-height: 1rem;
        }
      }
    }
  }
}


// @media (max-width: 767px) {
//   .tune-up-main-wrp {
//     padding: 0 15px;
//     .tune-up-tab {
//       margin-bottom: 20px;
//       ul {
//         li {
//             height: 50px;
//             font-size: 14px;
//         }
//       }
//     }
//     .tune-amt-wrp {
//       .tune-amt-cnt {
//         padding: 25px 0;
  
//         .tune-name-price,
//         .tune-duration {
//           font-size: 13px;
  
//           span {
//             font-size: 13px;
//           }
//         }
//       }
  
//       .tune-desc-wrp {
//         // margin-top: 25px;
//         // margin-bottom: 25px;
//         ul {
//           li {
//             font-size: 14px;
//           }
//         }
//       }
//     }
//   }
// }


// @media (max-width: 500px) {
//   .tune-up-main-wrp {
//     padding: 0 15px;
//     .tune-up-tab {
//       margin-bottom: 20px;
//       ul {
//         li {
//             height: 45px;
//             font-size: 13px;
//         }
//       }
//     }
//     .tune-amt-wrp {
//       .tune-amt-cnt {
//         padding: 15px 0;
//         align-items: center;
//         height: 124px;
//         background-size: 184px 137px;
//         .tune-name-price {
//           font-size: 13px;
//           display: flex;
//           flex-direction: column;
//           width: 100%;
//           text-align: center;
          
//           span {
//             font-size: 13px;
//           }
          
//         }
//         .tune-duration {
//           font-size: 13px;
//           display: flex;
//           flex-direction: row;
//           width: 100%;
//           align-items: center;
//           justify-content: center;
//         }
//       }
  
//       .tune-desc-wrp {
//         margin-top: -53px;
//         // margin-bottom: 25px;
//         ul {
//           li {
//             font-size: 14px;
//           }
//         }
//       }
//     }
//   }
// }
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-icon-add{
 width: 10%;
 padding: 10px;
 margin: 0 auto;
 
}

.loading-icon {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top-color: #FF5F07;
  border-radius: 50%;
  animation: rotate 1s infinite linear;
}