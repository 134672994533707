@import url(https://themes.googleusercontent.com/fonts/css?kit=wAPX1HepqA24RkYW1AuHYA);

.lst-kix_list_14-1>li:before {
    content: "o  "
}

.lst-kix_list_14-3>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_28-0 {
    list-style-type: none
}

.lst-kix_list_21-8>li {
    counter-increment: lst-ctn-kix_list_21-8
}

.lst-kix_list_14-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_14-4>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_28-1 {
    list-style-type: none
}

ol.lst-kix_list_30-0.start {
    counter-reset: lst-ctn-kix_list_30-0 0
}

.lst-kix_list_6-1>li {
    counter-increment: lst-ctn-kix_list_6-1
}

.lst-kix_list_14-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_14-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_14-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_30-6>li {
    counter-increment: lst-ctn-kix_list_30-6
}

ul.lst-kix_list_9-3 {
    list-style-type: none
}

ul.lst-kix_list_9-4 {
    list-style-type: none
}

ul.lst-kix_list_9-1 {
    list-style-type: none
}

ul.lst-kix_list_9-2 {
    list-style-type: none
}

ul.lst-kix_list_9-7 {
    list-style-type: none
}

ul.lst-kix_list_9-8 {
    list-style-type: none
}

ol.lst-kix_list_20-2.start {
    counter-reset: lst-ctn-kix_list_20-2 0
}

ul.lst-kix_list_9-5 {
    list-style-type: none
}

.lst-kix_list_5-0>li {
    counter-increment: lst-ctn-kix_list_5-0
}

ul.lst-kix_list_9-6 {
    list-style-type: none
}

ul.lst-kix_list_28-2 {
    list-style-type: none
}

ul.lst-kix_list_28-3 {
    list-style-type: none
}

ul.lst-kix_list_28-4 {
    list-style-type: none
}

ul.lst-kix_list_28-5 {
    list-style-type: none
}

ul.lst-kix_list_28-6 {
    list-style-type: none
}

ul.lst-kix_list_9-0 {
    list-style-type: none
}

ul.lst-kix_list_28-7 {
    list-style-type: none
}

ul.lst-kix_list_28-8 {
    list-style-type: none
}

.lst-kix_list_14-2>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_23-2.start {
    counter-reset: lst-ctn-kix_list_23-2 0
}

.lst-kix_list_20-7>li {
    counter-increment: lst-ctn-kix_list_20-7
}

ol.lst-kix_list_5-3.start {
    counter-reset: lst-ctn-kix_list_5-3 0
}

ul.lst-kix_list_17-1 {
    list-style-type: none
}

ul.lst-kix_list_17-0 {
    list-style-type: none
}

.lst-kix_list_4-3>li {
    counter-increment: lst-ctn-kix_list_4-3
}

ul.lst-kix_list_17-8 {
    list-style-type: none
}

ul.lst-kix_list_17-7 {
    list-style-type: none
}

ol.lst-kix_list_21-6.start {
    counter-reset: lst-ctn-kix_list_21-6 0
}

ul.lst-kix_list_17-6 {
    list-style-type: none
}

ul.lst-kix_list_17-5 {
    list-style-type: none
}

.lst-kix_list_29-8>li {
    counter-increment: lst-ctn-kix_list_29-8
}

.lst-kix_list_24-7>li {
    counter-increment: lst-ctn-kix_list_24-7
}

ul.lst-kix_list_17-4 {
    list-style-type: none
}

ul.lst-kix_list_17-3 {
    list-style-type: none
}

ol.lst-kix_list_30-5.start {
    counter-reset: lst-ctn-kix_list_30-5 0
}

.lst-kix_list_14-8>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_17-2 {
    list-style-type: none
}

.lst-kix_list_5-0>li:before {
    content: "" counter(lst-ctn-kix_list_5-0, lower-latin) ". "
}

ol.lst-kix_list_6-0 {
    list-style-type: none
}

ol.lst-kix_list_6-1 {
    list-style-type: none
}

ol.lst-kix_list_22-3.start {
    counter-reset: lst-ctn-kix_list_22-3 0
}

.lst-kix_list_5-4>li {
    counter-increment: lst-ctn-kix_list_5-4
}

.lst-kix_list_24-7>li:before {
    content: "" counter(lst-ctn-kix_list_24-7, decimal) ". "
}

ul.lst-kix_list_27-0 {
    list-style-type: none
}

ul.lst-kix_list_27-1 {
    list-style-type: none
}

.lst-kix_list_24-8>li:before {
    content: "" counter(lst-ctn-kix_list_24-8, decimal) ". "
}

ul.lst-kix_list_27-2 {
    list-style-type: none
}

.lst-kix_list_5-3>li:before {
    content: "" counter(lst-ctn-kix_list_5-3, lower-latin) ". "
}

ol.lst-kix_list_24-6.start {
    counter-reset: lst-ctn-kix_list_24-6 0
}

.lst-kix_list_5-2>li:before {
    content: "" counter(lst-ctn-kix_list_5-2, decimal) ". "
}

.lst-kix_list_5-1>li:before {
    content: "(" counter(lst-ctn-kix_list_5-1, lower-roman) ") "
}

.lst-kix_list_24-2>li:before {
    content: "" counter(lst-ctn-kix_list_24-2, decimal) ". "
}

.lst-kix_list_5-7>li:before {
    content: "" counter(lst-ctn-kix_list_5-7, lower-latin) ". "
}

ul.lst-kix_list_8-4 {
    list-style-type: none
}

ul.lst-kix_list_8-5 {
    list-style-type: none
}

ol.lst-kix_list_20-7.start {
    counter-reset: lst-ctn-kix_list_20-7 0
}

.lst-kix_list_5-6>li:before {
    content: "" counter(lst-ctn-kix_list_5-6, lower-latin) ". "
}

.lst-kix_list_5-8>li:before {
    content: "" counter(lst-ctn-kix_list_5-8, lower-latin) ". "
}

ul.lst-kix_list_8-2 {
    list-style-type: none
}

ul.lst-kix_list_8-3 {
    list-style-type: none
}

ul.lst-kix_list_8-8 {
    list-style-type: none
}

.lst-kix_list_24-3>li:before {
    content: "" counter(lst-ctn-kix_list_24-3, decimal) ". "
}

ul.lst-kix_list_8-6 {
    list-style-type: none
}

.lst-kix_list_24-4>li:before {
    content: "" counter(lst-ctn-kix_list_24-4, decimal) ". "
}

ul.lst-kix_list_8-7 {
    list-style-type: none
}

ol.lst-kix_list_6-6 {
    list-style-type: none
}

ul.lst-kix_list_27-3 {
    list-style-type: none
}

.lst-kix_list_24-5>li:before {
    content: "" counter(lst-ctn-kix_list_24-5, decimal) ". "
}

ol.lst-kix_list_6-7 {
    list-style-type: none
}

ul.lst-kix_list_27-4 {
    list-style-type: none
}

.lst-kix_list_5-4>li:before {
    content: "" counter(lst-ctn-kix_list_5-4, lower-latin) ". "
}

ol.lst-kix_list_6-8 {
    list-style-type: none
}

ul.lst-kix_list_27-5 {
    list-style-type: none
}

ul.lst-kix_list_27-6 {
    list-style-type: none
}

.lst-kix_list_5-5>li:before {
    content: "" counter(lst-ctn-kix_list_5-5, lower-latin) ". "
}

ol.lst-kix_list_6-2 {
    list-style-type: none
}

ul.lst-kix_list_8-0 {
    list-style-type: none
}

ul.lst-kix_list_27-7 {
    list-style-type: none
}

ol.lst-kix_list_6-3 {
    list-style-type: none
}

ul.lst-kix_list_8-1 {
    list-style-type: none
}

ul.lst-kix_list_27-8 {
    list-style-type: none
}

ol.lst-kix_list_6-4 {
    list-style-type: none
}

.lst-kix_list_24-6>li:before {
    content: "" counter(lst-ctn-kix_list_24-6, decimal) ". "
}

ol.lst-kix_list_6-5 {
    list-style-type: none
}

.lst-kix_list_23-6>li:before {
    content: "" counter(lst-ctn-kix_list_23-6, decimal) ". "
}

.lst-kix_list_6-1>li:before {
    content: "" counter(lst-ctn-kix_list_6-1, decimal) ". "
}

.lst-kix_list_6-3>li:before {
    content: "" counter(lst-ctn-kix_list_6-3, decimal) ". "
}

.lst-kix_list_6-5>li {
    counter-increment: lst-ctn-kix_list_6-5
}

.lst-kix_list_6-8>li {
    counter-increment: lst-ctn-kix_list_6-8
}

.lst-kix_list_23-3>li:before {
    content: "" counter(lst-ctn-kix_list_23-3, decimal) ". "
}

.lst-kix_list_23-7>li:before {
    content: "" counter(lst-ctn-kix_list_23-7, decimal) ". "
}

.lst-kix_list_6-0>li:before {
    content: "" counter(lst-ctn-kix_list_6-0, decimal) ". "
}

.lst-kix_list_6-4>li:before {
    content: "" counter(lst-ctn-kix_list_6-4, decimal) ". "
}

.lst-kix_list_23-2>li:before {
    content: "" counter(lst-ctn-kix_list_23-2, decimal) ". "
}

ul.lst-kix_list_16-2 {
    list-style-type: none
}

ol.lst-kix_list_23-7.start {
    counter-reset: lst-ctn-kix_list_23-7 0
}

ul.lst-kix_list_16-1 {
    list-style-type: none
}

ul.lst-kix_list_16-0 {
    list-style-type: none
}

.lst-kix_list_23-0>li:before {
    content: "" counter(lst-ctn-kix_list_23-0, decimal) ". "
}

.lst-kix_list_23-8>li:before {
    content: "" counter(lst-ctn-kix_list_23-8, decimal) ". "
}

.lst-kix_list_23-1>li:before {
    content: "" counter(lst-ctn-kix_list_23-1, decimal) ". "
}

.lst-kix_list_6-2>li:before {
    content: "" counter(lst-ctn-kix_list_6-2, decimal) ". "
}

.lst-kix_list_24-1>li:before {
    content: "" counter(lst-ctn-kix_list_24-1, decimal) ". "
}

ul.lst-kix_list_16-8 {
    list-style-type: none
}

ul.lst-kix_list_16-7 {
    list-style-type: none
}

ul.lst-kix_list_16-6 {
    list-style-type: none
}

ul.lst-kix_list_16-5 {
    list-style-type: none
}

.lst-kix_list_24-0>li:before {
    content: "" counter(lst-ctn-kix_list_24-0, decimal) ". "
}

ul.lst-kix_list_16-4 {
    list-style-type: none
}

.lst-kix_list_6-8>li:before {
    content: "" counter(lst-ctn-kix_list_6-8, decimal) ". "
}

ul.lst-kix_list_16-3 {
    list-style-type: none
}

.lst-kix_list_6-5>li:before {
    content: "" counter(lst-ctn-kix_list_6-5, decimal) ". "
}

.lst-kix_list_6-7>li:before {
    content: "" counter(lst-ctn-kix_list_6-7, decimal) ". "
}

.lst-kix_list_23-4>li:before {
    content: "" counter(lst-ctn-kix_list_23-4, decimal) ". "
}

.lst-kix_list_23-5>li:before {
    content: "" counter(lst-ctn-kix_list_23-5, decimal) ". "
}

.lst-kix_list_6-6>li:before {
    content: "" counter(lst-ctn-kix_list_6-6, decimal) ". "
}

.lst-kix_list_7-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_7-6>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0
}

.lst-kix_list_22-2>li:before {
    content: "" counter(lst-ctn-kix_list_22-2, lower-roman) ". "
}

.lst-kix_list_22-6>li:before {
    content: "" counter(lst-ctn-kix_list_22-6, decimal) ". "
}

ol.lst-kix_list_24-1.start {
    counter-reset: lst-ctn-kix_list_24-1 0
}

.lst-kix_list_22-2>li {
    counter-increment: lst-ctn-kix_list_22-2
}

.lst-kix_list_7-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_22-0>li:before {
    content: "" counter(lst-ctn-kix_list_22-0, decimal) ". "
}

.lst-kix_list_22-8>li:before {
    content: "" counter(lst-ctn-kix_list_22-8, lower-roman) ". "
}

ol.lst-kix_list_22-8.start {
    counter-reset: lst-ctn-kix_list_22-8 0
}

ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
}

ol.lst-kix_list_22-5.start {
    counter-reset: lst-ctn-kix_list_22-5 0
}

.lst-kix_list_24-3>li {
    counter-increment: lst-ctn-kix_list_24-3
}

.lst-kix_list_13-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_5-7>li {
    counter-increment: lst-ctn-kix_list_5-7
}

.lst-kix_list_7-8>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_23-0.start {
    counter-reset: lst-ctn-kix_list_23-0 0
}

.lst-kix_list_4-7>li {
    counter-increment: lst-ctn-kix_list_4-7
}

.lst-kix_list_22-4>li:before {
    content: "" counter(lst-ctn-kix_list_22-4, lower-latin) ". "
}

.lst-kix_list_15-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_4-1>li:before {
    content: "" counter(lst-ctn-kix_list_4-1, lower-latin) ". "
}

.lst-kix_list_15-7>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_19-7 {
    list-style-type: none
}

ul.lst-kix_list_19-6 {
    list-style-type: none
}

.lst-kix_list_4-3>li:before {
    content: "" counter(lst-ctn-kix_list_4-3, lower-latin) ". "
}

.lst-kix_list_4-5>li:before {
    content: "" counter(lst-ctn-kix_list_4-5, lower-latin) ". "
}

ul.lst-kix_list_19-5 {
    list-style-type: none
}

ul.lst-kix_list_19-4 {
    list-style-type: none
}

ol.lst-kix_list_29-2.start {
    counter-reset: lst-ctn-kix_list_29-2 0
}

ul.lst-kix_list_19-3 {
    list-style-type: none
}

.lst-kix_list_25-5>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_19-2 {
    list-style-type: none
}

ul.lst-kix_list_19-1 {
    list-style-type: none
}

ul.lst-kix_list_19-0 {
    list-style-type: none
}

.lst-kix_list_15-1>li:before {
    content: "o  "
}

.lst-kix_list_24-4>li {
    counter-increment: lst-ctn-kix_list_24-4
}

.lst-kix_list_25-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_15-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_22-1>li {
    counter-increment: lst-ctn-kix_list_22-1
}

ol.lst-kix_list_24-4.start {
    counter-reset: lst-ctn-kix_list_24-4 0
}

ul.lst-kix_list_19-8 {
    list-style-type: none
}

ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
}

.lst-kix_list_6-4>li {
    counter-increment: lst-ctn-kix_list_6-4
}

.lst-kix_list_30-2>li {
    counter-increment: lst-ctn-kix_list_30-2
}

.lst-kix_list_20-0>li {
    counter-increment: lst-ctn-kix_list_20-0
}

.lst-kix_list_30-3>li {
    counter-increment: lst-ctn-kix_list_30-3
}

.lst-kix_list_12-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_12-1>li:before {
    content: "o  "
}

.lst-kix_list_23-6>li {
    counter-increment: lst-ctn-kix_list_23-6
}

ol.lst-kix_list_29-8 {
    list-style-type: none
}

ol.lst-kix_list_29-7 {
    list-style-type: none
}

ol.lst-kix_list_29-4 {
    list-style-type: none
}

ol.lst-kix_list_29-3 {
    list-style-type: none
}

.lst-kix_list_21-4>li {
    counter-increment: lst-ctn-kix_list_21-4
}

ol.lst-kix_list_29-6 {
    list-style-type: none
}

.lst-kix_list_13-3>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_29-5 {
    list-style-type: none
}

ol.lst-kix_list_29-0 {
    list-style-type: none
}

ul.lst-kix_list_18-0 {
    list-style-type: none
}

ol.lst-kix_list_5-8.start {
    counter-reset: lst-ctn-kix_list_5-8 0
}

ol.lst-kix_list_29-2 {
    list-style-type: none
}

ol.lst-kix_list_29-1 {
    list-style-type: none
}

.lst-kix_list_13-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_12-5>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_18-8 {
    list-style-type: none
}

ul.lst-kix_list_18-7 {
    list-style-type: none
}

ul.lst-kix_list_18-6 {
    list-style-type: none
}

ul.lst-kix_list_18-5 {
    list-style-type: none
}

.lst-kix_list_12-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_29-4>li {
    counter-increment: lst-ctn-kix_list_29-4
}

ul.lst-kix_list_18-4 {
    list-style-type: none
}

ul.lst-kix_list_18-3 {
    list-style-type: none
}

ul.lst-kix_list_18-2 {
    list-style-type: none
}

ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0
}

ol.lst-kix_list_21-1.start {
    counter-reset: lst-ctn-kix_list_21-1 0
}

ul.lst-kix_list_18-1 {
    list-style-type: none
}

ol.lst-kix_list_29-0.start {
    counter-reset: lst-ctn-kix_list_29-0 0
}

.lst-kix_list_4-2>li {
    counter-increment: lst-ctn-kix_list_4-2
}

.lst-kix_list_25-1>li {
    counter-increment: lst-ctn-kix_list_25-1
}

.lst-kix_list_13-1>li:before {
    content: "o  "
}

.lst-kix_list_24-8>li {
    counter-increment: lst-ctn-kix_list_24-8
}

.lst-kix_list_30-5>li:before {
    content: "" counter(lst-ctn-kix_list_30-5, lower-roman) ". "
}

.lst-kix_list_5-1>li {
    counter-increment: lst-ctn-kix_list_5-1
}

.lst-kix_list_22-8>li {
    counter-increment: lst-ctn-kix_list_22-8
}

ol.lst-kix_list_21-3.start {
    counter-reset: lst-ctn-kix_list_21-3 0
}

.lst-kix_list_30-1>li:before {
    content: "" counter(lst-ctn-kix_list_30-1, lower-latin) ". "
}

.lst-kix_list_3-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_30-2>li:before {
    content: "" counter(lst-ctn-kix_list_30-2, lower-roman) ". "
}

ol.lst-kix_list_20-5.start {
    counter-reset: lst-ctn-kix_list_20-5 0
}

.lst-kix_list_21-8>li:before {
    content: "" counter(lst-ctn-kix_list_21-8, lower-roman) ". "
}

.lst-kix_list_26-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_4-0>li {
    counter-increment: lst-ctn-kix_list_4-0
}

.lst-kix_list_3-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_3-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_26-8>li:before {
    content: "\0025aa   "
}

.lst-kix_list_8-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_30-6>li:before {
    content: "" counter(lst-ctn-kix_list_30-6, decimal) ". "
}

.lst-kix_list_8-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_3-8>li:before {
    content: "\0025aa   "
}

.lst-kix_list_21-0>li:before {
    content: "" counter(lst-ctn-kix_list_21-0, decimal) ". "
}

.lst-kix_list_26-1>li:before {
    content: "o  "
}

.lst-kix_list_21-1>li:before {
    content: "" counter(lst-ctn-kix_list_21-1, lower-latin) ". "
}

.lst-kix_list_8-3>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_13-5 {
    list-style-type: none
}

ol.lst-kix_list_22-7 {
    list-style-type: none
}

ul.lst-kix_list_13-4 {
    list-style-type: none
}

ol.lst-kix_list_22-6 {
    list-style-type: none
}

ul.lst-kix_list_13-3 {
    list-style-type: none
}

ul.lst-kix_list_13-2 {
    list-style-type: none
}

ol.lst-kix_list_22-8 {
    list-style-type: none
}

ul.lst-kix_list_13-1 {
    list-style-type: none
}

.lst-kix_list_3-7>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_22-3 {
    list-style-type: none
}

ul.lst-kix_list_13-0 {
    list-style-type: none
}

.lst-kix_list_26-4>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_22-2 {
    list-style-type: none
}

.lst-kix_list_8-4>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_22-5 {
    list-style-type: none
}

ol.lst-kix_list_22-4 {
    list-style-type: none
}

ol.lst-kix_list_22-1 {
    list-style-type: none
}

ol.lst-kix_list_22-0 {
    list-style-type: none
}

ul.lst-kix_list_13-8 {
    list-style-type: none
}

.lst-kix_list_11-1>li:before {
    content: "o  "
}

ul.lst-kix_list_13-7 {
    list-style-type: none
}

ul.lst-kix_list_13-6 {
    list-style-type: none
}

.lst-kix_list_21-5>li:before {
    content: "" counter(lst-ctn-kix_list_21-5, lower-roman) ". "
}

.lst-kix_list_21-4>li:before {
    content: "" counter(lst-ctn-kix_list_21-4, lower-latin) ". "
}

.lst-kix_list_11-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_26-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_8-8>li:before {
    content: "\0025aa   "
}

.lst-kix_list_16-8>li:before {
    content: "\0025aa   "
}

.lst-kix_list_16-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_4-8>li:before {
    content: "" counter(lst-ctn-kix_list_4-8, lower-latin) ". "
}

.lst-kix_list_21-5>li {
    counter-increment: lst-ctn-kix_list_21-5
}

.lst-kix_list_4-7>li:before {
    content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
}

ol.lst-kix_list_20-0.start {
    counter-reset: lst-ctn-kix_list_20-0 0
}

.lst-kix_list_17-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_16-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_21-0>li {
    counter-increment: lst-ctn-kix_list_21-0
}

ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
}

.lst-kix_list_25-1>li:before {
    content: "" counter(lst-ctn-kix_list_25-1, decimal) ". "
}

.lst-kix_list_25-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_16-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_16-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_30-1>li {
    counter-increment: lst-ctn-kix_list_30-1
}

ul.lst-kix_list_12-6 {
    list-style-type: none
}

ol.lst-kix_list_23-6 {
    list-style-type: none
}

ul.lst-kix_list_12-5 {
    list-style-type: none
}

ol.lst-kix_list_23-5 {
    list-style-type: none
}

.lst-kix_list_17-7>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_12-4 {
    list-style-type: none
}

ol.lst-kix_list_23-8 {
    list-style-type: none
}

ul.lst-kix_list_12-3 {
    list-style-type: none
}

ol.lst-kix_list_23-7 {
    list-style-type: none
}

ul.lst-kix_list_12-2 {
    list-style-type: none
}

ol.lst-kix_list_23-2 {
    list-style-type: none
}

ul.lst-kix_list_12-1 {
    list-style-type: none
}

ol.lst-kix_list_21-4.start {
    counter-reset: lst-ctn-kix_list_21-4 0
}

ol.lst-kix_list_23-1 {
    list-style-type: none
}

.lst-kix_list_17-8>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_12-0 {
    list-style-type: none
}

ol.lst-kix_list_23-4 {
    list-style-type: none
}

ol.lst-kix_list_23-3 {
    list-style-type: none
}

.lst-kix_list_17-3>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_23-0 {
    list-style-type: none
}

.lst-kix_list_17-4>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_12-8 {
    list-style-type: none
}

ol.lst-kix_list_20-6.start {
    counter-reset: lst-ctn-kix_list_20-6 0
}

ul.lst-kix_list_12-7 {
    list-style-type: none
}

.lst-kix_list_7-0>li:before {
    content: "\0025cf   "
}

ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
}

ol.lst-kix_list_5-0 {
    list-style-type: none
}

ol.lst-kix_list_5-1 {
    list-style-type: none
}

ol.lst-kix_list_5-2 {
    list-style-type: none
}

.lst-kix_list_22-5>li:before {
    content: "" counter(lst-ctn-kix_list_22-5, lower-roman) ". "
}

ul.lst-kix_list_26-0 {
    list-style-type: none
}

.lst-kix_list_2-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-8>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_26-1 {
    list-style-type: none
}

ul.lst-kix_list_26-2 {
    list-style-type: none
}

ul.lst-kix_list_26-3 {
    list-style-type: none
}

.lst-kix_list_21-2>li {
    counter-increment: lst-ctn-kix_list_21-2
}

.lst-kix_list_22-1>li:before {
    content: "" counter(lst-ctn-kix_list_22-1, lower-latin) ". "
}

.lst-kix_list_27-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_20-2>li {
    counter-increment: lst-ctn-kix_list_20-2
}

.lst-kix_list_6-6>li {
    counter-increment: lst-ctn-kix_list_6-6
}

.lst-kix_list_7-3>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_7-5 {
    list-style-type: none
}

.lst-kix_list_10-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_list_7-6 {
    list-style-type: none
}

.lst-kix_list_23-3>li {
    counter-increment: lst-ctn-kix_list_23-3
}

ul.lst-kix_list_7-3 {
    list-style-type: none
}

ol.lst-kix_list_21-7.start {
    counter-reset: lst-ctn-kix_list_21-7 0
}

ul.lst-kix_list_7-4 {
    list-style-type: none
}

.lst-kix_list_13-8>li:before {
    content: "\0025aa   "
}

.lst-kix_list_18-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_18-7>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_7-7 {
    list-style-type: none
}

ul.lst-kix_list_7-8 {
    list-style-type: none
}

ol.lst-kix_list_5-7 {
    list-style-type: none
}

ul.lst-kix_list_26-4 {
    list-style-type: none
}

ol.lst-kix_list_5-8 {
    list-style-type: none
}

ul.lst-kix_list_26-5 {
    list-style-type: none
}

ul.lst-kix_list_26-6 {
    list-style-type: none
}

ul.lst-kix_list_26-7 {
    list-style-type: none
}

ol.lst-kix_list_5-3 {
    list-style-type: none
}

ul.lst-kix_list_7-1 {
    list-style-type: none
}

ul.lst-kix_list_26-8 {
    list-style-type: none
}

ol.lst-kix_list_5-4 {
    list-style-type: none
}

ul.lst-kix_list_7-2 {
    list-style-type: none
}

.lst-kix_list_27-0>li:before {
    content: "\0025cf   "
}

ol.lst-kix_list_5-5 {
    list-style-type: none
}

ol.lst-kix_list_5-6 {
    list-style-type: none
}

ul.lst-kix_list_7-0 {
    list-style-type: none
}

.lst-kix_list_7-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_20-4>li {
    counter-increment: lst-ctn-kix_list_20-4
}

.lst-kix_list_15-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_5-8>li {
    counter-increment: lst-ctn-kix_list_5-8
}

ol.lst-kix_list_24-8 {
    list-style-type: none
}

.lst-kix_list_10-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_10-8>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_20-4.start {
    counter-reset: lst-ctn-kix_list_20-4 0
}

.lst-kix_list_4-0>li:before {
    content: "" counter(lst-ctn-kix_list_4-0, lower-latin) ". "
}

ol.lst-kix_list_24-5 {
    list-style-type: none
}

ul.lst-kix_list_15-3 {
    list-style-type: none
}

ol.lst-kix_list_24-4 {
    list-style-type: none
}

ul.lst-kix_list_15-2 {
    list-style-type: none
}

ol.lst-kix_list_24-7 {
    list-style-type: none
}

.lst-kix_list_15-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_list_15-1 {
    list-style-type: none
}

.lst-kix_list_15-8>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_24-6 {
    list-style-type: none
}

ul.lst-kix_list_15-0 {
    list-style-type: none
}

ol.lst-kix_list_24-1 {
    list-style-type: none
}

ol.lst-kix_list_24-0 {
    list-style-type: none
}

ol.lst-kix_list_24-3 {
    list-style-type: none
}

ol.lst-kix_list_24-2 {
    list-style-type: none
}

.lst-kix_list_4-4>li:before {
    content: "" counter(lst-ctn-kix_list_4-4, lower-latin) ". "
}

ol.lst-kix_list_20-1.start {
    counter-reset: lst-ctn-kix_list_20-1 0
}

.lst-kix_list_25-4>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_15-8 {
    list-style-type: none
}

ul.lst-kix_list_15-7 {
    list-style-type: none
}

ul.lst-kix_list_15-6 {
    list-style-type: none
}

.lst-kix_list_9-3>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_15-5 {
    list-style-type: none
}

ul.lst-kix_list_15-4 {
    list-style-type: none
}

.lst-kix_list_24-1>li {
    counter-increment: lst-ctn-kix_list_24-1
}

.lst-kix_list_25-8>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_4-0 {
    list-style-type: none
}

ol.lst-kix_list_4-1 {
    list-style-type: none
}

ol.lst-kix_list_25-1.start {
    counter-reset: lst-ctn-kix_list_25-1 0
}

ol.lst-kix_list_4-2 {
    list-style-type: none
}

ol.lst-kix_list_4-3 {
    list-style-type: none
}

ul.lst-kix_list_25-0 {
    list-style-type: none
}

ul.lst-kix_list_25-2 {
    list-style-type: none
}

.lst-kix_list_9-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_29-4>li:before {
    content: "" counter(lst-ctn-kix_list_29-4, lower-latin) ". "
}

.lst-kix_list_29-8>li:before {
    content: "" counter(lst-ctn-kix_list_29-8, lower-latin) ". "
}

ul.lst-kix_list_25-3 {
    list-style-type: none
}

ul.lst-kix_list_25-4 {
    list-style-type: none
}

ol.lst-kix_list_21-8.start {
    counter-reset: lst-ctn-kix_list_21-8 0
}

.lst-kix_list_11-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_22-5>li {
    counter-increment: lst-ctn-kix_list_22-5
}

.lst-kix_list_12-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_5-3>li {
    counter-increment: lst-ctn-kix_list_5-3
}

.lst-kix_list_20-5>li:before {
    content: "" counter(lst-ctn-kix_list_20-5, lower-roman) ". "
}

.lst-kix_list_29-0>li:before {
    content: "" counter(lst-ctn-kix_list_29-0, lower-latin) ". "
}

ol.lst-kix_list_4-8 {
    list-style-type: none
}

.lst-kix_list_28-8>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_25-5 {
    list-style-type: none
}

.lst-kix_list_1-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_list_25-6 {
    list-style-type: none
}

.lst-kix_list_20-1>li:before {
    content: "" counter(lst-ctn-kix_list_20-1, lower-latin) ". "
}

ul.lst-kix_list_25-7 {
    list-style-type: none
}

ul.lst-kix_list_25-8 {
    list-style-type: none
}

ol.lst-kix_list_4-4 {
    list-style-type: none
}

.lst-kix_list_11-8>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_4-5 {
    list-style-type: none
}

.lst-kix_list_24-6>li {
    counter-increment: lst-ctn-kix_list_24-6
}

ol.lst-kix_list_4-6 {
    list-style-type: none
}

.lst-kix_list_12-0>li:before {
    content: "\0025cf   "
}

ol.lst-kix_list_4-7 {
    list-style-type: none
}

.lst-kix_list_28-4>li:before {
    content: "o  "
}

.lst-kix_list_1-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_13-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_21-7>li {
    counter-increment: lst-ctn-kix_list_21-7
}

ul.lst-kix_list_14-4 {
    list-style-type: none
}

ul.lst-kix_list_14-3 {
    list-style-type: none
}

ul.lst-kix_list_14-2 {
    list-style-type: none
}

.lst-kix_list_13-4>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_14-1 {
    list-style-type: none
}

ul.lst-kix_list_14-0 {
    list-style-type: none
}

ol.lst-kix_list_25-1 {
    list-style-type: none
}

ul.lst-kix_list_14-8 {
    list-style-type: none
}

ul.lst-kix_list_14-7 {
    list-style-type: none
}

.lst-kix_list_2-0>li:before {
    content: "\0025cf   "
}

ul.lst-kix_list_14-6 {
    list-style-type: none
}

ul.lst-kix_list_14-5 {
    list-style-type: none
}

.lst-kix_list_4-5>li {
    counter-increment: lst-ctn-kix_list_4-5
}

.lst-kix_list_28-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_1-8>li:before {
    content: "\0025aa   "
}

.lst-kix_list_27-8>li:before {
    content: "\0025aa   "
}

.lst-kix_list_12-8>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_20-3.start {
    counter-reset: lst-ctn-kix_list_20-3 0
}

.lst-kix_list_29-1>li {
    counter-increment: lst-ctn-kix_list_29-1
}

.lst-kix_list_4-1>li {
    counter-increment: lst-ctn-kix_list_4-1
}

.lst-kix_list_19-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_19-1>li:before {
    content: "o  "
}

ul.lst-kix_list_1-0 {
    list-style-type: none
}

.lst-kix_list_23-8>li {
    counter-increment: lst-ctn-kix_list_23-8
}

.lst-kix_list_19-4>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_30-6 {
    list-style-type: none
}

ol.lst-kix_list_30-5 {
    list-style-type: none
}

.lst-kix_list_19-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_19-3>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_30-8 {
    list-style-type: none
}

ol.lst-kix_list_21-0.start {
    counter-reset: lst-ctn-kix_list_21-0 0
}

ol.lst-kix_list_30-7 {
    list-style-type: none
}

ol.lst-kix_list_30-2 {
    list-style-type: none
}

ol.lst-kix_list_30-1 {
    list-style-type: none
}

ol.lst-kix_list_30-4 {
    list-style-type: none
}

ol.lst-kix_list_24-5.start {
    counter-reset: lst-ctn-kix_list_24-5 0
}

ol.lst-kix_list_30-3 {
    list-style-type: none
}

ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0
}

ol.lst-kix_list_30-0 {
    list-style-type: none
}

ol.lst-kix_list_29-6.start {
    counter-reset: lst-ctn-kix_list_29-6 0
}

ul.lst-kix_list_1-3 {
    list-style-type: none
}

ul.lst-kix_list_1-4 {
    list-style-type: none
}

ul.lst-kix_list_1-1 {
    list-style-type: none
}

ul.lst-kix_list_1-2 {
    list-style-type: none
}

ul.lst-kix_list_1-7 {
    list-style-type: none
}

ul.lst-kix_list_1-8 {
    list-style-type: none
}

ul.lst-kix_list_1-5 {
    list-style-type: none
}

ul.lst-kix_list_1-6 {
    list-style-type: none
}

.lst-kix_list_29-6>li {
    counter-increment: lst-ctn-kix_list_29-6
}

ol.lst-kix_list_22-4.start {
    counter-reset: lst-ctn-kix_list_22-4 0
}

.lst-kix_list_22-7>li {
    counter-increment: lst-ctn-kix_list_22-7
}

ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
}

.lst-kix_list_19-8>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_20-8.start {
    counter-reset: lst-ctn-kix_list_20-8 0
}

.lst-kix_list_5-2>li {
    counter-increment: lst-ctn-kix_list_5-2
}

.lst-kix_list_19-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_19-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_19-7>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_23-8.start {
    counter-reset: lst-ctn-kix_list_23-8 0
}

.lst-kix_list_24-5>li {
    counter-increment: lst-ctn-kix_list_24-5
}

ol.lst-kix_list_21-5.start {
    counter-reset: lst-ctn-kix_list_21-5 0
}

.lst-kix_list_20-5>li {
    counter-increment: lst-ctn-kix_list_20-5
}

.lst-kix_list_22-0>li {
    counter-increment: lst-ctn-kix_list_22-0
}

ol.lst-kix_list_24-0.start {
    counter-reset: lst-ctn-kix_list_24-0 0
}

.lst-kix_list_21-6>li {
    counter-increment: lst-ctn-kix_list_21-6
}

.lst-kix_list_18-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_18-1>li:before {
    content: "o  "
}

.lst-kix_list_18-2>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
}

.lst-kix_list_23-4>li {
    counter-increment: lst-ctn-kix_list_23-4
}

ol.lst-kix_list_23-1.start {
    counter-reset: lst-ctn-kix_list_23-1 0
}

.lst-kix_list_30-4>li {
    counter-increment: lst-ctn-kix_list_30-4
}

.lst-kix_list_23-1>li {
    counter-increment: lst-ctn-kix_list_23-1
}

.lst-kix_list_2-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_27-1>li:before {
    content: "o  "
}

.lst-kix_list_24-2>li {
    counter-increment: lst-ctn-kix_list_24-2
}

.lst-kix_list_2-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_27-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_27-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_22-3>li {
    counter-increment: lst-ctn-kix_list_22-3
}

.lst-kix_list_18-6>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_3-7 {
    list-style-type: none
}

ul.lst-kix_list_3-8 {
    list-style-type: none
}

.lst-kix_list_10-1>li:before {
    content: "o  "
}

.lst-kix_list_21-3>li {
    counter-increment: lst-ctn-kix_list_21-3
}

.lst-kix_list_18-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_18-8>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_3-1 {
    list-style-type: none
}

ul.lst-kix_list_3-2 {
    list-style-type: none
}

ol.lst-kix_list_30-1.start {
    counter-reset: lst-ctn-kix_list_30-1 0
}

ul.lst-kix_list_3-0 {
    list-style-type: none
}

ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
}

ul.lst-kix_list_3-5 {
    list-style-type: none
}

ul.lst-kix_list_3-6 {
    list-style-type: none
}

ul.lst-kix_list_3-3 {
    list-style-type: none
}

ul.lst-kix_list_3-4 {
    list-style-type: none
}

.lst-kix_list_10-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_20-1>li {
    counter-increment: lst-ctn-kix_list_20-1
}

.lst-kix_list_10-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_29-3>li {
    counter-increment: lst-ctn-kix_list_29-3
}

.lst-kix_list_10-3>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_11-7 {
    list-style-type: none
}

ul.lst-kix_list_11-6 {
    list-style-type: none
}

ol.lst-kix_list_20-8 {
    list-style-type: none
}

ul.lst-kix_list_11-5 {
    list-style-type: none
}

ul.lst-kix_list_11-4 {
    list-style-type: none
}

ul.lst-kix_list_11-3 {
    list-style-type: none
}

ol.lst-kix_list_20-5 {
    list-style-type: none
}

ul.lst-kix_list_11-2 {
    list-style-type: none
}

ol.lst-kix_list_20-4 {
    list-style-type: none
}

ul.lst-kix_list_11-1 {
    list-style-type: none
}

ol.lst-kix_list_20-7 {
    list-style-type: none
}

ul.lst-kix_list_11-0 {
    list-style-type: none
}

ol.lst-kix_list_20-6 {
    list-style-type: none
}

.lst-kix_list_9-2>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_20-1 {
    list-style-type: none
}

ol.lst-kix_list_20-0 {
    list-style-type: none
}

ol.lst-kix_list_20-3 {
    list-style-type: none
}

ol.lst-kix_list_20-2 {
    list-style-type: none
}

ol.lst-kix_list_5-7.start {
    counter-reset: lst-ctn-kix_list_5-7 0
}

ul.lst-kix_list_11-8 {
    list-style-type: none
}

.lst-kix_list_20-8>li:before {
    content: "" counter(lst-ctn-kix_list_20-8, lower-roman) ". "
}

.lst-kix_list_5-5>li {
    counter-increment: lst-ctn-kix_list_5-5
}

.lst-kix_list_9-0>li:before {
    content: "\0025cf   "
}

.lst-kix_list_29-7>li:before {
    content: "" counter(lst-ctn-kix_list_29-7, lower-latin) ". "
}

.lst-kix_list_29-5>li:before {
    content: "" counter(lst-ctn-kix_list_29-5, lower-latin) ". "
}

.lst-kix_list_20-0>li:before {
    content: "" counter(lst-ctn-kix_list_20-0, decimal) ". "
}

.lst-kix_list_9-6>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_24-3.start {
    counter-reset: lst-ctn-kix_list_24-3 0
}

.lst-kix_list_9-4>li:before {
    content: "\0025aa   "
}

.lst-kix_list_30-8>li {
    counter-increment: lst-ctn-kix_list_30-8
}

.lst-kix_list_23-5>li {
    counter-increment: lst-ctn-kix_list_23-5
}

.lst-kix_list_11-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_6-3>li {
    counter-increment: lst-ctn-kix_list_6-3
}

.lst-kix_list_20-6>li:before {
    content: "" counter(lst-ctn-kix_list_20-6, decimal) ". "
}

.lst-kix_list_23-0>li {
    counter-increment: lst-ctn-kix_list_23-0
}

ul.lst-kix_list_2-8 {
    list-style-type: none
}

ol.lst-kix_list_29-1.start {
    counter-reset: lst-ctn-kix_list_29-1 0
}

.lst-kix_list_29-1>li:before {
    content: "(" counter(lst-ctn-kix_list_29-1, lower-roman) ") "
}

.lst-kix_list_20-4>li:before {
    content: "" counter(lst-ctn-kix_list_20-4, lower-latin) ". "
}

.lst-kix_list_11-5>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_22-7.start {
    counter-reset: lst-ctn-kix_list_22-7 0
}

ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0
}

ul.lst-kix_list_2-2 {
    list-style-type: none
}

.lst-kix_list_29-3>li:before {
    content: "" counter(lst-ctn-kix_list_29-3, lower-latin) ". "
}

.lst-kix_list_20-2>li:before {
    content: "" counter(lst-ctn-kix_list_20-2, lower-roman) ". "
}

ol.lst-kix_list_21-2.start {
    counter-reset: lst-ctn-kix_list_21-2 0
}

ul.lst-kix_list_2-3 {
    list-style-type: none
}

ul.lst-kix_list_2-0 {
    list-style-type: none
}

ul.lst-kix_list_2-1 {
    list-style-type: none
}

.lst-kix_list_9-8>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_2-6 {
    list-style-type: none
}

.lst-kix_list_20-6>li {
    counter-increment: lst-ctn-kix_list_20-6
}

.lst-kix_list_1-1>li:before {
    content: "o  "
}

ul.lst-kix_list_2-7 {
    list-style-type: none
}

.lst-kix_list_11-7>li:before {
    content: "\0025aa   "
}

ul.lst-kix_list_2-4 {
    list-style-type: none
}

ul.lst-kix_list_2-5 {
    list-style-type: none
}

ul.lst-kix_list_10-0 {
    list-style-type: none
}

.lst-kix_list_1-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_28-3>li:before {
    content: "\0025cf   "
}

.lst-kix_list_28-7>li:before {
    content: "o  "
}

ul.lst-kix_list_10-8 {
    list-style-type: none
}

ol.lst-kix_list_21-8 {
    list-style-type: none
}

.lst-kix_list_4-8>li {
    counter-increment: lst-ctn-kix_list_4-8
}

ul.lst-kix_list_10-7 {
    list-style-type: none
}

.lst-kix_list_1-7>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_21-7 {
    list-style-type: none
}

ul.lst-kix_list_10-6 {
    list-style-type: none
}

ul.lst-kix_list_10-5 {
    list-style-type: none
}

ul.lst-kix_list_10-4 {
    list-style-type: none
}

ol.lst-kix_list_21-4 {
    list-style-type: none
}

ul.lst-kix_list_10-3 {
    list-style-type: none
}

.lst-kix_list_1-5>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_21-3 {
    list-style-type: none
}

ul.lst-kix_list_10-2 {
    list-style-type: none
}

.lst-kix_list_28-5>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_21-6 {
    list-style-type: none
}

ul.lst-kix_list_10-1 {
    list-style-type: none
}

ol.lst-kix_list_21-5 {
    list-style-type: none
}

ol.lst-kix_list_24-2.start {
    counter-reset: lst-ctn-kix_list_24-2 0
}

.lst-kix_list_5-6>li {
    counter-increment: lst-ctn-kix_list_5-6
}

ol.lst-kix_list_21-0 {
    list-style-type: none
}

ol.lst-kix_list_22-6.start {
    counter-reset: lst-ctn-kix_list_22-6 0
}

ol.lst-kix_list_21-2 {
    list-style-type: none
}

ol.lst-kix_list_21-1 {
    list-style-type: none
}

.lst-kix_list_2-1>li:before {
    content: "o  "
}

.lst-kix_list_27-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_2-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_28-1>li:before {
    content: "o  "
}

ol.lst-kix_list_24-8.start {
    counter-reset: lst-ctn-kix_list_24-8 0
}

.lst-kix_list_30-3>li:before {
    content: "" counter(lst-ctn-kix_list_30-3, decimal) ". "
}

.lst-kix_list_30-4>li:before {
    content: "" counter(lst-ctn-kix_list_30-4, lower-latin) ". "
}

.lst-kix_list_30-8>li:before {
    content: "" counter(lst-ctn-kix_list_30-8, lower-roman) ". "
}

.lst-kix_list_20-8>li {
    counter-increment: lst-ctn-kix_list_20-8
}

.lst-kix_list_30-5>li {
    counter-increment: lst-ctn-kix_list_30-5
}

.lst-kix_list_3-1>li:before {
    content: "o  "
}

.lst-kix_list_3-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_26-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_26-7>li:before {
    content: "\0025aa   "
}

.lst-kix_list_8-1>li:before {
    content: "o  "
}

.lst-kix_list_8-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_6-0>li {
    counter-increment: lst-ctn-kix_list_6-0
}

.lst-kix_list_3-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_30-7>li:before {
    content: "" counter(lst-ctn-kix_list_30-7, lower-latin) ". "
}

ol.lst-kix_list_30-8.start {
    counter-reset: lst-ctn-kix_list_30-8 0
}

.lst-kix_list_21-2>li:before {
    content: "" counter(lst-ctn-kix_list_21-2, lower-roman) ". "
}

.lst-kix_list_8-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_8-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_26-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_26-3>li:before {
    content: "\0025aa   "
}

.lst-kix_list_3-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_21-6>li:before {
    content: "" counter(lst-ctn-kix_list_21-6, decimal) ". "
}

.lst-kix_list_21-7>li:before {
    content: "" counter(lst-ctn-kix_list_21-7, lower-latin) ". "
}

ol.lst-kix_list_5-0.start {
    counter-reset: lst-ctn-kix_list_5-0 0
}

.lst-kix_list_11-2>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_22-1.start {
    counter-reset: lst-ctn-kix_list_22-1 0
}

ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
}

.lst-kix_list_21-3>li:before {
    content: "" counter(lst-ctn-kix_list_21-3, decimal) ". "
}

ol.lst-kix_list_30-2.start {
    counter-reset: lst-ctn-kix_list_30-2 0
}

.lst-kix_list_16-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_4-4>li {
    counter-increment: lst-ctn-kix_list_4-4
}

ol.lst-kix_list_29-4.start {
    counter-reset: lst-ctn-kix_list_29-4 0
}

ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0
}

.lst-kix_list_25-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_17-1>li:before {
    content: "o  "
}

.lst-kix_list_29-5>li {
    counter-increment: lst-ctn-kix_list_29-5
}

.lst-kix_list_25-3>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_5-6.start {
    counter-reset: lst-ctn-kix_list_5-6 0
}

.lst-kix_list_22-6>li {
    counter-increment: lst-ctn-kix_list_22-6
}

.lst-kix_list_16-1>li:before {
    content: "o  "
}

ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
}

.lst-kix_list_16-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_16-5>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_22-2.start {
    counter-reset: lst-ctn-kix_list_22-2 0
}

ol.lst-kix_list_30-3.start {
    counter-reset: lst-ctn-kix_list_30-3 0
}

.lst-kix_list_30-7>li {
    counter-increment: lst-ctn-kix_list_30-7
}

ol.lst-kix_list_29-3.start {
    counter-reset: lst-ctn-kix_list_29-3 0
}

.lst-kix_list_23-7>li {
    counter-increment: lst-ctn-kix_list_23-7
}

ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0
}

.lst-kix_list_17-2>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_5-5.start {
    counter-reset: lst-ctn-kix_list_5-5 0
}

.lst-kix_list_30-0>li:before {
    content: "" counter(lst-ctn-kix_list_30-0, decimal) ". "
}

.lst-kix_list_17-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_17-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_6-2>li {
    counter-increment: lst-ctn-kix_list_6-2
}

.lst-kix_list_2-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_27-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_22-3>li:before {
    content: "" counter(lst-ctn-kix_list_22-3, decimal) ". "
}

.lst-kix_list_7-1>li:before {
    content: "o  "
}

.lst-kix_list_7-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_29-2>li {
    counter-increment: lst-ctn-kix_list_29-2
}

.lst-kix_list_27-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_22-7>li:before {
    content: "" counter(lst-ctn-kix_list_22-7, lower-latin) ". "
}

ol.lst-kix_list_23-3.start {
    counter-reset: lst-ctn-kix_list_23-3 0
}

.lst-kix_list_23-2>li {
    counter-increment: lst-ctn-kix_list_23-2
}

ol.lst-kix_list_30-4.start {
    counter-reset: lst-ctn-kix_list_30-4 0
}

.lst-kix_list_30-0>li {
    counter-increment: lst-ctn-kix_list_30-0
}

ol.lst-kix_list_5-4.start {
    counter-reset: lst-ctn-kix_list_5-4 0
}

.lst-kix_list_18-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_13-6>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_5-1.start {
    counter-reset: lst-ctn-kix_list_5-1 0
}

.lst-kix_list_20-3>li {
    counter-increment: lst-ctn-kix_list_20-3
}

.lst-kix_list_6-7>li {
    counter-increment: lst-ctn-kix_list_6-7
}

.lst-kix_list_15-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_29-0>li {
    counter-increment: lst-ctn-kix_list_29-0
}

ol.lst-kix_list_24-7.start {
    counter-reset: lst-ctn-kix_list_24-7 0
}

.lst-kix_list_22-4>li {
    counter-increment: lst-ctn-kix_list_22-4
}

ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0
}

.lst-kix_list_10-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_4-6>li {
    counter-increment: lst-ctn-kix_list_4-6
}

.lst-kix_list_20-7>li:before {
    content: "" counter(lst-ctn-kix_list_20-7, lower-latin) ". "
}

ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0
}

.lst-kix_list_4-2>li:before {
    content: "" counter(lst-ctn-kix_list_4-2, lower-latin) ". "
}

.lst-kix_list_4-6>li:before {
    content: "" counter(lst-ctn-kix_list_4-6, lower-latin) ". "
}

ol.lst-kix_list_23-6.start {
    counter-reset: lst-ctn-kix_list_23-6 0
}

ol.lst-kix_list_29-8.start {
    counter-reset: lst-ctn-kix_list_29-8 0
}

.lst-kix_list_15-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_25-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_10-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_9-1>li:before {
    content: "o  "
}

ol.lst-kix_list_29-5.start {
    counter-reset: lst-ctn-kix_list_29-5 0
}

ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0
}

.lst-kix_list_9-5>li:before {
    content: "\0025aa   "
}

.lst-kix_list_29-6>li:before {
    content: "" counter(lst-ctn-kix_list_29-6, lower-latin) ". "
}

ol.lst-kix_list_22-0.start {
    counter-reset: lst-ctn-kix_list_22-0 0
}

.lst-kix_list_24-0>li {
    counter-increment: lst-ctn-kix_list_24-0
}

.lst-kix_list_12-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_11-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_20-3>li:before {
    content: "" counter(lst-ctn-kix_list_20-3, decimal) ". "
}

ol.lst-kix_list_30-6.start {
    counter-reset: lst-ctn-kix_list_30-6 0
}

.lst-kix_list_1-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_29-2>li:before {
    content: "" counter(lst-ctn-kix_list_29-2, decimal) ". "
}

ol.lst-kix_list_23-5.start {
    counter-reset: lst-ctn-kix_list_23-5 0
}

.lst-kix_list_21-1>li {
    counter-increment: lst-ctn-kix_list_21-1
}

.lst-kix_list_28-6>li:before {
    content: "\0025cf   "
}

.lst-kix_list_1-6>li:before {
    content: "\0025aa   "
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

ol.lst-kix_list_29-7.start {
    counter-reset: lst-ctn-kix_list_29-7 0
}

.lst-kix_list_12-6>li:before {
    content: "\0025aa   "
}

.lst-kix_list_29-7>li {
    counter-increment: lst-ctn-kix_list_29-7
}

ol.lst-kix_list_23-4.start {
    counter-reset: lst-ctn-kix_list_23-4 0
}

ol.lst-kix_list_30-7.start {
    counter-reset: lst-ctn-kix_list_30-7 0
}

.lst-kix_list_2-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_13-2>li:before {
    content: "\0025aa   "
}

.lst-kix_list_28-2>li:before {
    content: "\0025aa   "
}

ol.lst-kix_list_5-2.start {
    counter-reset: lst-ctn-kix_list_5-2 0
}

ol {
    margin: 0;
    padding: 0
}

table td,
table th {
    padding: 0
}

.list-content3 {
    margin-left: 108pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

@media(max-width: 768px) {
    .list-content3{
        margin-left: 36pt;
    }
}

.s-content4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: "Dubai Light";
    font-style: normal
}
@media(max-width: 768px){
  .s-content4{
    font-size: 6pt;
  }
}

.s-content3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Dubai Light";
    font-style: normal
}

.p-content5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 12pt
}

.c21 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal
}

.s-content11 {
    color: #022022;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Dubai Light";
    font-style: normal
}

.s-content8 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Dubai Light";
    font-style: normal
}

.c20 {
    color: #0c0b31;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Arial";
    font-style: normal
}

.s-content5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Dubai Light";
    font-style: normal
}

.p-content2 {
    padding-top: 0pt;
    padding-bottom: 12pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.title-content {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: "Dubai Light";
    font-style: normal
}

.p-content6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.p-content1 {
    padding-top: 0pt;
    padding-bottom: 6pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.list-content4 {
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.s-content12 {
    padding-top: 14pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.p-content4 {
    padding-top: 6pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}
@media(max-width: 768px){
    .p-content4{
        line-height: 0;
    }
}

.s-content10 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Calibri";
    font-style: normal
}

.s-content6 {
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Dubai Light";
    font-style: normal
}

.p-content9 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

.c25 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    text-align: left
}

.s-content9 {
    font-size: 11pt;
    font-family: "Dubai Light";
    font-weight: 700
}

.s-content1 {
    font-size: 11pt;
    font-family: "Dubai Light";
    font-weight: 400
}

.list-content2 {
    margin-left: 36pt;
    padding-left: 0pt
}
@media(max-width: 768px) {
    .list-content2{
        margin-left: 25pt;
    }
}

.main-content {
    max-width: 451.3pt;
    padding: 72pt 72pt 72pt 72pt
}
@media(max-width: 768px){
    .main-content{
        padding: 72pt 72pt 72pt 30pt;
    }
}

.list-content1 {
    padding: 0;
    margin: 0
}

.link1 {
    color: inherit;
    text-decoration: inherit
}

.p-content7 {
    margin-left: 36pt
}

.s-content2 {
    color: #000000
}

.p-content8 {
    text-indent: -18pt
}

.p-content3 {
    height: 12pt
}

.s-content7 {
    background-color: #ff00ff
}

.p-content10 {
    background-color: #ffffff
}

.title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

.subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

.para-list {
    color: #000000;
    font-size: 12pt;
    font-family: "Times New Roman"
}

.para-content {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: "Times New Roman"
}

// h1 {
//     padding-top: 0pt;
//     color: #000000;
//     font-weight: 700;
//     font-size: 24pt;
//     padding-bottom: 0pt;
//     font-family: "Times New Roman";
//     line-height: 1.0;
//     orphans: 2;
//     widows: 2;
//     text-align: left
// }

// h2 {
//     padding-top: 0pt;
//     color: #000000;
//     font-weight: 700;
//     font-size: 18pt;
//     padding-bottom: 0pt;
//     font-family: "Times New Roman";
//     line-height: 1.0;
//     orphans: 2;
//     widows: 2;
//     text-align: left
// }

// h3 {
//     padding-top: 0pt;
//     color: #000000;
//     font-weight: 700;
//     font-size: 13.5pt;
//     padding-bottom: 0pt;
//     font-family: "Times New Roman";
//     line-height: 1.0;
//     orphans: 2;
//     widows: 2;
//     text-align: left
// }

// h4 {
//     padding-top: 12pt;
//     color: #000000;
//     font-weight: 700;
//     font-size: 12pt;
//     padding-bottom: 2pt;
//     font-family: "Times New Roman";
//     line-height: 1.0;
//     page-break-after: avoid;
//     orphans: 2;
//     widows: 2;
//     text-align: left
// }

// h5 {
//     padding-top: 0pt;
//     color: #000000;
//     font-weight: 700;
//     font-size: 10pt;
//     padding-bottom: 0pt;
//     font-family: "Times New Roman";
//     line-height: 1.0;
//     orphans: 2;
//     widows: 2;
//     text-align: left
// }

// h6 {
//     padding-top: 10pt;
//     color: #000000;
//     font-weight: 700;
//     font-size: 10pt;
//     padding-bottom: 2pt;
//     font-family: "Times New Roman";
//     line-height: 1.0;
//     page-break-after: avoid;
//     orphans: 2;
//     widows: 2;
//     text-align: left
// }

//Headers

.header-terms-wrp {
    max-width: 395px;

    header {
        height: 70px;
        // background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: left;
        padding-left: 72pt;
        // border-bottom: 1px solid #f1f1f1;
        // box-shadow: 0px 0px 2px rgb(0 0 0 / 15%);
        // border-radius: 0px 0px 50px 0px;

        &.hd-centered {
            justify-content: center;
            text-align: center;
            padding-left: 0;
        }

        img {
            max-width: 260px;
            // margin-top: 6px;
        }

        .header-terms-backic {
            background-color: #FFF2EB;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
            width: 36px;
            height: 36px;
            border-radius: 36px;
            display: flex;
            align-items: center;
            justify-content: center;

            .header-terms-backic-icon {
                display: flex;
                background: transparent;
                border: none;
                cursor: pointer;
            }
        }

        .header-mnutxt {
            // padding-left: 20px;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 18px;
            text-align: center;
            color: #000000;
        }
    }
}
@media(max-width: 768px) {
    header{
        padding-left: 20px !important;
    }
}