@import '../../scss/colors.scss';

.footer-main-wrp-in {
    position: relative;
    padding-bottom: 80px;
    max-width: 600px;
    margin: 0 auto;
    .footer-lst{
        position: fixed;
        max-width: 600px;
        width: 100%;
        bottom: 0px;
        margin: 0 auto;
        height: 70px;
        background-color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        // border-bottom: 1px solid #f1f1f1;
        background: #FFFFFF;
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
        // border-radius: 50px 0px 0px 0px;
        // padding-top: 3px;
        .footer-lst-in{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-left: 10px;
            margin-right: 10px;
        }
        // @media(max-width: 740px){
        //     .footer-lst-in{
        //         width: 100%;
        //         display: flex;
        //         justify-content: space-between;
        //         margin-left: 3%;
        //         margin-right: 3%;
        //     }
        // }
        .footer-item{
            display: flex;
            .footer-in{
                display: flex;
                flex-direction: column;
                .footer-link-in{
                    display: flex;
                    flex-direction: column;
                    width: 40px;
                    height: 30px;
                    justify-content: center;
                    align-items: center;
                    // border-radius: 0px 15px 0px 0px;
                    transition: 0.3s ease-in-out;
                    color: $black;
                    svg{
                        path{
                            fill: $black;
                            transition: 0.3s ease-in-out;
                        }
                    }
                }
                &.active, &:hover {
                    .footer-link-in{
                        // background-color: $footer-active-bg;
                        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
                        transition: 0.3s ease-in-out;
                        svg{
                            path{
                                fill: $primary;
                                transition: 0.3s ease-in-out;
                            }
                        }

                    }
                    .footer-txt{
                        // color: $primary;
                        // font-weight: 500;
                        // transition: 0.3s ease-in-out;
                    }
                    .svg-icon{
                    filter: #FF5F07 !important;
                    transition: 0.3s ease-in-out;

                    }
                }
                
            }
            .footer-txt{
                display: block;
                padding-top:6px;
                font-size: 1rem;
                line-height: 14px;
                color: $dark;
                font-weight: 600;
            }

            // .footer-left{
            //     margin-left: 25px !important;
            // }

            // .footer-left-text{
            //     margin-left: 25px !important;
            // }

            // .footer-right{
            //     margin-right: 30px !important;
            // }

            // .footer-right-text{

            //     margin-right: 45px !important;
            // }
        }
    }

}

// .footer-lst-in-profile{
//     width: 100%;
//     display: flex;
//     justify-content: space-between;
// }


@media (max-width: 599px) {
    .footer-main-wrp-in .footer-lst{
        height: 60px;
    }
    .footer-home svg{
        width: 1.8rem;
        height: 1.8rem;
    }
    .footer-profile svg{
        width: 1.8rem;
        height: 1.8rem;
    }
    .footer-main-wrp-in .footer-lst .footer-item .footer-in .footer-link-in{
        width: 35px;
        height: 26px;
    }
    // .footer-main-wrp-in .footer-lst .footer-item .footer-txt{
    //     font-size: 13px;
    // }
}